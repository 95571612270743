<template>
  <div class="d-flex align-center" style="align-items: center">
    <!-- {{ $store.state.activeProcessNode }}

    {{ breadCrumbItems }} -->

    <v-breadcrumbs :items="breadCrumbItems" class="py-0 my-0">
      <template v-slot:divider>
        <v-icon>mdi-chevron-right</v-icon>
      </template>
    </v-breadcrumbs>
  </div>
</template>

<script>
import { makeGetMixin } from 'feathers-vuex';
export default {
  mixins: [
    makeGetMixin({
      service: 'process',
      id: 'processId',
    }),
    makeGetMixin({
      service: 'end-2-end',
      id: 'end2EndId',
    }),
    makeGetMixin({
      service: 'variant',
      id: 'variantId',
    }),
    makeGetMixin({
      service: 'test-scenario-header',
      id: 'testScenarioHeaderId',
    }),
    makeGetMixin({
      service: 'test-scenario-line',
      id: 'testScenarioLineId',
    }),
  ],

  computed: {
    processId() {
      return this.$store.state.activeProcessNode.ProcessId;
    },
    variantId() {
      return this.$store.state.activeProcessNode.VariantId;
    },
    end2EndId() {
      return this.$store.state.activeProcessNode.End2EndId;
    },
    testScenarioHeaderId() {
      return this.$store.state.activeProcessNode.TestScenarioHeaderId;
    },
    testScenarioLineId() {
      return this.$store.state.activeProcessNode.TestScenarioLineId;
    },
    breadCrumbItems() {
      const items = [];
      if (this.testScenarioHeaderId) {
        items.push({
          text: 'Test Suite',
          disabled: true,
        });
        items.push({
          text: this.testScenarioHeader?.Name,
          disabled: false,
          href: `/#/testScenario/${this.testScenarioHeader?.id}`,
        });
      }
      if (this.end2End) {
        items.push({
          text: 'End 2 End',
          disabled: true,
        });
        items.push({
          text: this.end2End?.Name,
          disabled: false,
          href: `/#/processe2e/${this.end2End.id}`,
        });
      }
      if (this.process) {
        items.push({
          text: this.process?.Name,
          disabled: false,
          href: `/#/processDetail/${this.process.id}&${this.process.Number}`,
        });
      }
      if (this.variant) {
        items.push({
          text: this.variant?.Name,
          disabled: false,
          href: `/#/processStep/${this.variant.id}`,
        });
      }
      return items;
    },
  },
};
</script>

<style lang="scss" scoped></style>
