<template>
  <v-container>
    <v-overlay :value="generatingDocument">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card class="mt-5">
      <v-card-title> <span>Test Scenario Suites</span></v-card-title>
      <v-row>
        <v-col cols="12" md="5" class="ml-2">
          Filters
          <v-btn icon @click="show = !show">
            <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
          </v-btn>

          <v-tooltip bottom v-if="filterupdated">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2 mb-1"
                color="primary"
                @click="saveFilter"
                v-bind="attrs"
                v-on="on"
                >mdi-filter-plus</v-icon
              ></template
            >Save Filter
          </v-tooltip>
          <v-tooltip bottom v-if="filterExists">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="ml-2 mb-1"
                color="primary"
                @click="clearFilter"
                v-bind="attrs"
                v-on="on"
                >mdi-filter-remove</v-icon
              ></template
            >Clear Filter</v-tooltip
          >
        </v-col>
        <v-col cols="12" md="6" align="right">
          <v-icon
            v-if="$can('create', 'test-scenario-header')"
            class="ml-5"
            @click="
              $router.push({
                name: 'testscenariobuilder',
                params: { id: 0 },
              })
            "
            color="primary"
            large
          >
            mdi-plus-circle
          </v-icon></v-col
        >
      </v-row>

      <v-expand-transition>
        <v-card flat v-if="show" class="my-0 py-2">
          <v-card-title v-if="$route.name != 'TestScenariosOverview'">
            <span class="headline">Test Scenario Suites</span
            ><v-spacer></v-spacer>
            <v-icon class="ml-5" @click="showDialog = false" color="primary">
              mdi-close-box
            </v-icon>
          </v-card-title>
          <v-card-title v-if="$route.name == 'TestScenariosOverview'">
            <v-row
              ><v-col cols="12" md="8" class="my-0 py-2"
                ><v-text-field
                  v-model="selectionFilter.search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  clearable
                  @change="filterupdated = true"
                ></v-text-field></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.testScenarioType"
                  :items="testScenarioType"
                  item-text="Name"
                  item-value="id"
                  label="Test Scenario Type"
                  clearable
                  multiple
                  hide-details
                  dense
                  small-chips
                  @change="filterupdated = true"
                >
                </v-autocomplete></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.process"
                  :items="processList"
                  item-text="fullname"
                  item-value="id"
                  label="Process"
                  clearable
                  multiple
                  hide-details
                  dense
                  small-chips
                  @change="filterupdated = true"
                >
                </v-autocomplete></v-col
              ><v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.variant"
                  :items="variantList"
                  item-text="fullname"
                  item-value="id"
                  label="Variant"
                  clearable
                  multiple
                  hide-details
                  dense
                  small-chips
                  @change="filterupdated = true"
                >
                </v-autocomplete
              ></v-col>
              <v-col cols="12" md="4" class="my-0 py-2">
                <v-autocomplete
                  v-model="selectionFilter.department"
                  :items="department"
                  item-text="Name"
                  item-value="id"
                  label="Department"
                  clearable
                  multiple
                  hide-details
                  dense
                  small-chips
                  @change="filterupdated = true"
                >
                </v-autocomplete></v-col
            ></v-row>
          </v-card-title>
          <v-card-title v-else>
            <v-row
              ><v-col cols="12" md="4"
                ><v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                  clearable
                ></v-text-field></v-col
            ></v-row>
          </v-card-title>

          <v-card-text class="my-2">
            <v-row><v-spacer></v-spacer> </v-row
          ></v-card-text>
        </v-card>
      </v-expand-transition>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="filteredTestScenarioHeaders"
      :items-per-page="15"
      :sort-by="['id']"
      :sort-desc="[true]"
      item-key="id"
      class="custom-table elevation-1"
      item-class="topalign"
      show-expand
      fixed-header
      height="calc(100vh - 400px)"
      :loading="isFindTestScenarioHeaderPending"
      :hide-default-footer="
        $route.name == 'TestScenariosOverview' ? false : true
      "
      :disable-pagination="
        $route.name == 'TestScenariosOverview' ? false : true
      "
    >
      <template v-slot:[`item.print`]="{ item }">
        <v-icon
          v-if="$can('print', 'testscenarioheader')"
          small
          class="mr-2"
          @click="showPrint(item)"
          color="primary"
        >
          mdi-printer-outline
        </v-icon>

        <v-icon class="mr-2" small @click="confirmCopy(item)"
          >mdi-content-copy</v-icon
        >
        <v-icon small @click="syncItem = item">mdi-sync</v-icon>
      </template>
      <template v-slot:[`item.steps`]="{ item }">
        {{ item.totalSteps }}
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon
          v-if="$can('update', subject('test-scenario-header', item))"
          small
          class="mr-2"
          @click="
            $router.push({
              name: 'testscenariobuilder',
              params: { id: item.id },
            })
          "
        >
          mdi-pencil
        </v-icon></template
      >
      <template v-slot:[`item.Tests`]="{ item }">
        <v-chip
          small
          :color="
            item.testCounterSuccess == item.testCounterAll &&
            item.testCounterAll != 0
              ? 'secondary'
              : item.testCounterSuccess < item.testCounterAll
              ? 'warning'
              : ''
          "
          text-color="black"
        >
          {{ item.testCounterSuccess }} /{{ item.testCounterAll }}</v-chip
        >
      </template>
      <template v-slot:[`item.Description`]="{ item }">
        <span v-html="item.Description"> </span>
      </template>
      <template v-slot:[`item.Run`]="{ item }">
        <v-icon color="green" class="mr-2" @click="confirmRuns(item)">
          mdi-run
        </v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers?.length">
          <TestSummary class="my-2" :testScenarioHeader="item" />
          <testScenarioLine :testScenarioHeader="item" />
        </td>
      </template>
    </v-data-table>

    <!-- <v-dialog v-model="dialogAdd" persistent max-width="500">
      <v-form ref="form" v-model="validInput">
        <v-card>
          <v-card-title class="text-h5">Add Test Run</v-card-title>
          <v-card-text class="ma-0 px-8"
            ><v-row
              ><v-col cols="12" md="12"
                >Testrun wil be added for '<b>{{
                  addRunItem ? addRunItem.Name : ''
                }}</b
                >'</v-col
              ><v-col cols="12" md="5">Number of runs to add</v-col
              ><v-col cols="12" md="2"
                ><v-text-field
                  dense
                  hide-details
                  type="number"
                  :disabled="createRun"
                  v-model="addNumber"
                  :rules="[rules.required, rules.number]"
                >
                </v-text-field></v-col
            ></v-row>
            <v-row><v-col> </v-col></v-row>
            <v-row
              ><v-col class="mx-5">
                <v-progress-linear
                  v-show="createRun"
                  class="mt-5"
                  :value="(addedCount / addNumber) * 100"
                ></v-progress-linear></v-col
            ></v-row>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="createRun" outlined @click="dialogAdd = false">
              Cancel
            </v-btn>
            <v-btn
              :disabled="!validInput"
              color="primary"
              :loading="createRun"
              @click="clickAddRuns()"
            >
              Add Run
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog> -->
    <Modal
      v-if="dialogAdd"
      width="800px"
      title="Add Run"
      @cancel="dialogAdd = false"
      @confirm="$refs.AddRun.clickAddRuns()"
      :busy="createRun"
      :disabledConfirm="addNumber <= 0"
    >
      <AddRun
        ref="AddRun"
        :addRunItem="addRunItem"
        @addRun="clickAddRuns"
        @nrOfRunsChanged="addNumber = $event"
        @createRun="createRun = $event"
        @closeDialog="dialogAdd = $event"
      />
    </Modal>

    <Modal
      v-if="showCopy"
      title="Do you want to copy this Test Suite?"
      @cancel="showCopy = false"
      @confirm="showCopy = false"
      :busy="copyingSuite"
    >
      <v-text-field v-model="dataToCopy.Name" label="Name" outlined dense>
      </v-text-field>
    </Modal>
    <PrintModal
      v-if="showPrintDialog"
      v-model="showPrintDialog"
      :itemtoPrint="itemtoPrint"
      printType="TestScenario"
    />
    <Modal
      v-if="syncItem"
      title="Sync Test Suite"
      :busy="isSyncing"
      @cancel="syncItem = null"
      @confirm="sync(syncItem)"
    >
      {{ syncItem.testCounterAll - syncItem.testCounterSuccess }} open test Runs
      will be synced.
    </Modal>
  </v-container>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex/dist';
import { mapGetters } from 'vuex';
import { applyDark } from '@/utils/Utilities';
import { subject } from '@casl/ability';
import Modal from '@/components/general/Modal.vue';
import TestSummary from '@/components/TestScenarios/TestScenarioBuilder/TestSummaries.vue';

import TestScenarioLine from './TestScenarioLine.vue';
import PrintModal from '@/components/general/PrintModal.vue';
import AddRun from './AddRun.vue';
import { SyncTestRun } from '@/utils/SyncTestRun';

import {
  handleSaveResponse,
  handleErrorResponse,
} from '@/utils/MessageHandler';

export default {
  mixins: [
    makeFindMixin({
      service: 'test-scenario-header',
      watch: [
        'selectionFilter.testScenarioType.length',
        'selectionFilter.process.length',
        'selectionFilter.variant.length',
        'selectionFilter.department.length',
        'showCopy',
      ],
      queryWhen: 'queryAllowed',
    }),
    makeFindMixin({ service: 'test-scenario-type' }),
    makeFindMixin({ service: 'process' }),
    makeFindMixin({ service: 'department' }),
    makeFindMixin({ service: 'variant', watch: 'selectionFilter.process' }),
  ],
  components: {
    TestScenarioLine,
    Modal,
    TestSummary,
    PrintModal,
    AddRun,
  },
  data() {
    return {
      validInput: true,
      selectionFilter: {
        process: [],
        variant: [],
        testScenarioType: [],
        department: [],
      },
      search: null,
      filterExists: null,
      filterupdated: false,
      show: false,
      createRun: false,
      dialogAdd: false,
      addRunItem: null,
      addNumber: 1,
      addedCount: 0,
      queryAllowed: false,
      generatingDocument: false,
      showCopy: false,
      copyingSuite: false,
      dataToCopy: {},
      syncItem: null,
      isSyncing: false,
      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value?.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      showPrintDialog: false,
      itemtoPrint: null,
    };
  },

  props: {
    value: { type: Boolean },
  },
  methods: {
    applyDark,
    subject,
    handleSaveResponse,
    handleErrorResponse,
    showPrint(item) {
      this.showPrintDialog = true;
      this.itemtoPrint = item;
    },
    async sync(item) {
      this.isSyncing = true;
      const { TestScenarioRun } = this.$FeathersVuex.api;
      const TestRuns = await TestScenarioRun.find({
        query: { TestScenarioHeaderId: item.id },
      });

      for (const run of TestRuns.data) {
        await SyncTestRun(run, this);
      }
      this.isSyncing = false;
      this.syncItem = null;
      location.reload();
    },
    openWindow(url) {
      window.open(
        url,
        'popup',
        'width=1440,height=1024,scrollbars=no,resizable=no'
      );
      return false;
    },
    confirmRuns(item) {
      this.addRunItem = item;
      this.dialogAdd = true;
    },
    async clickAddRuns() {
      this.createRun = true;

      //reorder to fix issue when ther is consecutive numbering
      const { TestScenarioLine } = this.$FeathersVuex.api;
      let lines = await TestScenarioLine.find({
        query: { TestScenarioHeaderId: this.addRunItem.id },
      });

      for (const [index, line] of lines.data
        .sort((a, b) => a.Order - b.Order)
        .entries()) {
        line.Order = index + 1;
        await line.save();
      }

      for (let i = 0; i < this.addNumber; i++) {
        await this.clickRun();
        this.addedCount = i + 1;
      }
      this.dialogAdd = false;
      this.createRun = false;
      this.addedCount = 0;
      location.reload();
    },

    async clickRun() {
      let item = this.addRunItem;
      this.createRun = true;
      // Create New Test Run
      const {
        Variant,
        TestScenarioRun,
        TestScenarioLine,
        TestScenarioRunDetail,
        TestScenarioRunSteps,
        TestScenarioLineStep,
        TestScenarioRunFieldValues,
        TestScenarioLineStepField,
      } = this.$FeathersVuex.api;
      let newRun = new TestScenarioRun();
      newRun.Number = item.Number;
      newRun.Name = item.Name;
      newRun.Description = item.Description;
      newRun.TestScenarioHeaderId = item.id;
      newRun.TestScenarioTypeId = item.TestScenarioTypeId;

      try {
        newRun = await newRun.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      // Get Lines
      const testLines = await TestScenarioLine.find({
        query: { TestScenarioHeaderId: item.id },
      });
      if (testLines.total > 0) {
        // Create Run Detail for each Test Line
        for (const line of testLines.data) {
          // departmetn Info
          const variant = await Variant.find({
            query: { id: line.VariantId },
          });

          let newRunDetail = new TestScenarioRunDetail();
          newRunDetail.Order = line.Order;
          // newRunDetail.Number = line.test_scenario.Number;
          newRunDetail.Name = line.Name;
          newRunDetail.ReferenceName = line.ReferenceName;
          newRunDetail.Description = line.Description;
          newRunDetail.TestScenarioRunId = newRun.id;
          // newRunDetail.TestScenarioId = line.test_scenario.id;
          newRunDetail.TestScenarioLineId = line.id;
          newRunDetail.Detailed = line.Detailed;
          newRunDetail.DepartmentId =
            variant.total > 0 ? variant.data[0].process.DepartmentId : null;

          try {
            newRunDetail = await newRunDetail.save();
          } catch (error) {
            handleErrorResponse(error);
          }

          // Get Steps
          const testSteps = await TestScenarioLineStep.find({
            query: { TestScenarioLineId: line.id, Selected: true },
          });
          if (testSteps.total > 0) {
            // Create Run DEtail/Steps detail for eacht line/steps}
            for (const step of testSteps.data) {
              let newRunStep = new TestScenarioRunSteps();
              newRunStep.Number = step.Number;
              newRunStep.Name = step.process_step.Name; //step.Name;
              newRunStep.TestRemark = step.TestRemark;
              newRunStep.ExpectedResults = step.ExpectedResults;
              newRunStep.GroupId = step.GroupId;
              newRunStep.GroupIteration = step.GroupIteration;
              newRunStep.Done = false;
              newRunStep.TestScenarioRunDetailId = newRunDetail.id;
              newRunStep.TestScenarioLineStepId = step.id;
              newRunStep.ProcessStepId = step.process_step.id;
              newRunStep.Groups = step.Groups;
              newRunStep.Key = step.Key;
              try {
                newRunStep = await newRunStep.save();
              } catch (error) {
                handleErrorResponse(error);
              }

              // Get Fields
              const testFields = await TestScenarioLineStepField.find({
                query: { TestScenarioLineStepId: step.id },
              });

              if (testFields.total > 0) {
                // Create Run Steps/Fields detail for eacht line/steps/fields
                for (const field of testFields.data) {
                  let newRunField = new TestScenarioRunFieldValues();
                  newRunField.Field = field.fieldvaluelist.field.Name;
                  newRunField.Name = field.fieldvaluelist.Name;
                  newRunField.Code = field.fieldvaluelist.Code;
                  newRunField.Name = field.fieldvaluelist.Value;
                  newRunField.TestScenarioRunDetailId = newRunDetail.id;
                  newRunField.TestScenarioRunStepsId = newRunStep.id;

                  try {
                    newRunField = await newRunField.save();
                  } catch (error) {
                    handleErrorResponse(error);
                  }
                }
              }
            }
          }
        }
        await TestScenarioRun.find({ query: { id: newRun.id } });
        // await TestScenarioRunDetail.find();
        // await TestScenarioRunSteps.find();
        // await TestScenarioRunFieldValues.find();

        this.createRun = false;
      }
    },
    saveFilter() {
      window.localStorage.setItem(
        'TestScenarioHeaderFilter',
        JSON.stringify(this.selectionFilter)
      );
      this.filterExists = true;
      this.filterupdated = false;
    },
    clearFilter() {
      window.localStorage.removeItem('TestScenarioHeaderFilter');
      this.selectionFilter = {};
      this.filterExists = false;
      this.filterupdated = false;
    },
    confirmCopy(data) {
      Object.assign(this.dataToCopy, data);
      this.showCopy = true;
    },
    async CopyTestScenario() {
      // Copy Full test scenario Header with lines, steps and fields
      this.copyingSuite = true;
      const {
        TestScenarioHeader,
        TestScenarioLine,
        TestScenarioLineStep,
        TestScenarioLineStepField,
        TestScenarioOutput,
      } = this.$FeathersVuex.api;

      const outputMapping = [];
      // Get TestScenario Header
      const TestScenarioHeaderToCopy = await TestScenarioHeader.get(
        this.dataToCopy.id
      );

      // Get All Lines for Header
      const TestScenarioLinesToCopy = await TestScenarioLine.find({
        query: { TestScenarioHeaderId: this.dataToCopy.id },
      });

      // Create New Header
      let newHeader = new TestScenarioHeader();
      Object.assign(newHeader, TestScenarioHeaderToCopy);
      newHeader.Name = this.dataToCopy.Name;
      delete newHeader.id;
      delete newHeader.CreatedById;
      delete newHeader.ModifiedById;
      delete newHeader.createdAt;
      delete newHeader.updatedAt;
      let savedHeader = null;

      try {
        savedHeader = await newHeader.save();
      } catch (error) {
        handleErrorResponse(error);
      }
      // Create Alle Lines on new Header
      for (const line of TestScenarioLinesToCopy.data) {
        let newLine = new TestScenarioLine();
        Object.assign(newLine, line);
        newLine.TestScenarioHeaderId = savedHeader.id;
        delete newLine.id;
        delete newLine.CreatedById;
        delete newLine.ModifiedById;
        delete newLine.createdAt;
        delete newLine.updatedAt;
        try {
          const savedLine = await newLine.save();

          // Copy all outputs
          const TestScenarioLineOutputsToCopy = await TestScenarioOutput.find({
            query: { TestScenarioLineId: line.id },
          });

          for (const output of TestScenarioLineOutputsToCopy.data) {
            let newOutput = new TestScenarioOutput();
            Object.assign(newOutput, output);
            newOutput.TestScenarioLineId = savedLine.id;
            delete newOutput.id;
            delete newOutput.CreatedById;
            delete newOutput.ModifiedById;
            delete newOutput.createdAt;
            delete newOutput.updatedAt;
            try {
              const saveOutput = await newOutput.save();
              const mapping = {
                oldId: output.id,
                newId: saveOutput.id,
              };
              outputMapping.push(mapping);
            } catch (error) {
              handleErrorResponse(error);
            }
          }

          // Get all steps
          const TestScenarioLineStepsToCopy = await TestScenarioLineStep.find({
            query: { TestScenarioLineId: line.id },
          });
          for (const step of TestScenarioLineStepsToCopy.data) {
            let newStep = new TestScenarioLineStep();
            Object.assign(newStep, step);
            newStep.TestScenarioLineId = savedLine.id;
            delete newStep.id;
            delete newStep.CreatedById;
            delete newStep.ModifiedById;
            delete newStep.createdAt;
            delete newStep.updatedAt;
            try {
              const savedStep = await newStep.save();

              // Copy all outputs on steps
              const TestScenarioLineStepOutputsToCopy =
                await TestScenarioOutput.find({
                  query: { TestScenarioLineStepId: step.id },
                });
              for (const output of TestScenarioLineStepOutputsToCopy.data) {
                let newOutput = new TestScenarioOutput();
                Object.assign(newOutput, output);
                newOutput.TestScenarioLineStepId = savedStep.id;
                delete newOutput.id;
                delete newOutput.CreatedById;
                delete newOutput.ModifiedById;
                delete newOutput.createdAt;
                delete newOutput.updatedAt;
                try {
                  const saveOutput = await newOutput.save();
                  const mapping = {
                    oldId: output.id,
                    newId: saveOutput.id,
                  };
                  outputMapping.push(mapping);
                } catch (error) {
                  handleErrorResponse(error);
                }
              }
              // Get all Fields
              const TestScenarioLineStepsFieldsToCopy =
                await TestScenarioLineStepField.find({
                  query: { TestScenarioLineStepId: step.id },
                });
              for (const field of TestScenarioLineStepsFieldsToCopy.data) {
                let newField = new TestScenarioLineStepField();
                Object.assign(newField, field);
                newField.TestScenarioLineStepId = savedStep.id;
                delete newField.id;
                delete newField.CreatedById;
                delete newField.ModifiedById;
                delete newField.createdAt;
                delete newField.updatedAt;
                try {
                  await newField.save();
                } catch (error) {
                  handleErrorResponse(error);
                }
              }
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        } catch (error) {
          handleErrorResponse(error);
        }
      }

      // loop through all newky created lines which have TestUnput and replace with ID from outputMapping
      const testLines = await TestScenarioLine.find({
        query: { TestScenarioHeaderId: savedHeader.id },
      });
      for (const line of testLines.data) {
        if (line.TestInput) {
          const mappingLookup = Object.fromEntries(
            outputMapping.map(({ oldId, newId }) => [oldId, newId])
          );

          // Replace numbers using the mapping
          line.TestInput = line.TestInput.map(
            (num) => mappingLookup[num] ?? num
          );

          try {
            await line.save();
          } catch (error) {
            handleErrorResponse(error);
          }
        }
        // Do the same for steps
        const testSteps = await TestScenarioLineStep.find({
          query: { TestScenarioLineId: line.id },
        });
        for (const step of testSteps.data) {
          if (step.TestInput) {
            const mappingLookup = Object.fromEntries(
              outputMapping.map(({ oldId, newId }) => [oldId, newId])
            );

            // Replace numbers using the mapping
            step.TestInput = step.TestInput.map(
              (num) => mappingLookup[num] ?? num
            );

            try {
              await step.save();
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        }
      }

      this.copyingSuite = false;
      this.showCopy = false;
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated',
    }),

    showDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },

    testScenarioHeaderParams() {
      const { testScenarioType, process, variant, department } =
        this.selectionFilter || {};

      const query = {};

      if (testScenarioType?.length > 0) {
        query.TestScenarioTypeId = { $in: testScenarioType };
      }

      if (process?.length > 0) {
        query.customProcess = { $in: process };
      }

      if (variant?.length > 0) {
        query.customVariant = { $in: variant };
      }
      if (department?.length > 0) {
        query.customDepartment = { $in: department };
      }
      return { query };
    },
    testScenarioTypeParams() {
      return {};
    },
    processParams() {
      return { query: { $sort: { Number: 1 } } };
    },
    departmentParams() {
      return { query: { $sort: { Name: 1 } } };
    },
    processList() {
      let list = [...this.process];
      list.map((item) => {
        item.fullname = `${item.Number}. ${item.Name}`;
      });
      return list;
    },
    variantParams() {
      const query = {};

      if (this.selectionFilter?.process?.length > 0) {
        query.ProcessId = { $in: this.selectionFilter?.process };
      }

      return {
        queryWhen: this.selectionFilter?.process?.length > 0,
        query,
      };
    },
    variantList() {
      let list = [...this.variant];
      list.map((item) => {
        item.fullname = `${item.process.Number}.${item.Number}. ${item.Name}`;
      });
      return list.sort((a, b) => {
        // Compare process.Number
        if (a.process?.Number < b.process?.Number) {
          return -1;
        }
        if (a.process?.Number > b.process?.Number) {
          return 1;
        }
        // If process.Number is the same, compare Number
        if (a.Number < b.Number) {
          return -1;
        }
        if (a.Number > b.Number) {
          return 1;
        }
        return 0;
      });
    },
    filteredTestScenarioHeaders() {
      if (this.testScenarioHeader) {
        let filtered = this.testScenarioHeaderLatestQuery?.response?.data;
        // if (
        //   this.selectionFilter.testScenarioType &&
        //   this.selectionFilter.testScenarioType.length > 0
        // ) {
        //   filtered = filtered.filter((f) => {
        //     return this.selectionFilter.testScenarioType.includes(
        //       f.TestScenarioTypeId
        //     );
        //   });
        // }
        // if (
        //   this.selectionFilter.process &&
        //   this.selectionFilter.process.length > 0
        // ) {
        //   const { TestScenarioLine } = this.$FeathersVuex.api;
        //   TestScenarioLine.find({
        //     query: { ProcessId: { $in: this.selectionFilter.process } },
        //   });
        // }

        if (this.selectionFilter.search)
          return filtered?.filter(
            (item) =>
              item.Name?.toLowerCase().includes(
                this.selectionFilter.search.toLowerCase()
              ) ||
              item.Description?.toLowerCase().includes(
                this.selectionFilter.search.toLowerCase()
              )
          );
        return filtered;
      } else return [];
    },
    headers() {
      let headers = [
        {
          text: '',
          align: 'center',
          sortable: false,
          value: 'print',
          width: '100px',
        },
        {
          text: 'Number',
          align: 'center',
          sortable: true,
          value: 'id',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: true,
          value: 'Name',
        },

        {
          text: 'Description',
          align: 'start',
          sortable: true,
          value: 'Description',
        },
        {
          text: 'Type',
          align: 'start',
          sortable: true,
          value: 'test_scenario_type.Name',
        },
        {
          text: 'Steps',
          align: 'start',
          sortable: true,
          value: 'steps',
        },

        {
          text: 'Tests',
          value: 'Tests',
          align: 'center',
          sortable: true,
          groupable: false,
        },
        {
          text: 'Edit',
          value: 'edit',
          sortable: false,
          groupable: false,
        },
        {
          text: 'Run',
          value: 'Run',
          sortable: false,
          groupable: false,
        },
        { text: '', value: 'data-table-expand' },
      ];

      return headers;
    },
  },
  mounted() {
    if (window.localStorage.getItem('TestScenarioHeaderFilter') === null) {
      this.selectionFilter = {};
    } else {
      this.selectionFilter = JSON.parse(
        window.localStorage.getItem('TestScenarioHeaderFilter')
      );
      this.filterExists = true;
    }
    this.queryAllowed = true;
  },
};
</script>

<style lang="scss" scoped></style>
