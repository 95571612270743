<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="testScenarioLine"
      :items-per-page="20"
      :sort-by="['Order']"
      :sort-desc="[false]"
      item-key="id"
      class="elevation-1"
      disable-pagination
      show-expand
      dense
      :hide-default-footer="true"
      :loading="isFindTestScenarioLinePending"
      loading-text="Loading... Please wait"
      :item-class="getRowClass"
    >
      <template v-slot:[`item.flow`]="{ item }">
        <DrawIO
          v-if="$feature('drawIOChartIntegration')"
          :fullscreen="true"
          :variantId="item.VariantId"
          :iconOnly="true"
        />
      </template>
      <template v-slot:[`item.steps`]="{ item }">
        <div v-if="!item.Detailed"><v-icon small>mdi-details</v-icon></div>
        <div v-else>{{ item.totalSteps }}</div>
      </template>
      <template v-slot:[`item.Order`]="{ item }">
        <!-- <v-icon
          color="black"
          :disabled="index == 0"
          @click="reOrder('up', item, index)"
          >mdi-chevron-up</v-icon
        >
        <v-icon
          color="black"
          :disabled="index == testScenarioLine.length - 1"
          @click="reOrder('down', item, index)"
          >mdi-chevron-down</v-icon
        > -->
        {{ item.Order }}
      </template>

      <template v-slot:[`item.Tests`]="{ item }">
        <v-chip
          x-small
          :color="
            item.testCounterSuccess == item.testCounterAll &&
            item.testCounterAll != 0
              ? 'secondary'
              : item.testCounterSuccess < item.testCounterAll
              ? 'warning'
              : ''
          "
          text-color="black"
        >
          {{ item.testCounterSuccess }} /{{ item.testCounterAll }}</v-chip
        >
      </template>
      <template v-slot:[`item.Testable`]="{ item }">
        <v-icon v-if="item?.TestableSteps - item?.NrOfSteps == 0" color="green"
          >mdi-check</v-icon
        >
        <v-tooltip v-else bottom>
          <template v-slot:activator="{ on, attrs }"
            ><v-icon color="red" v-bind="attrs" v-on="on"
              >mdi-close</v-icon
            > </template
          >{{ item.TestableSteps }}/{{ item.NrOfSteps }}</v-tooltip
        >
      </template>
      <template v-slot:[`item.edit`]="{ item }">
        <v-icon small @click="EditLine(item)">mdi-pencil</v-icon>
      </template>
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <v-row
            ><v-col class="mt-2"
              ><TestScenarioLineSteps
                :keyReload="key"
                :testScenarioLine="item" /></v-col
          ></v-row>
        </td>
      </template>
    </v-data-table>
    <div v-if="testScenarioLine && showDialog">
      <TestScenarioEdit
        v-if="showDialog"
        v-model="showDialog"
        :currentItem="currentItem"
        :variant="{}"
        :testScenarioHeader="testScenarioHeader"
        :maxOrder="0"
      />
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import TestScenarioLineSteps from './TestScenarioLineSteps.vue';
import TestScenarioEdit from '../TestScenario/TestScenarioEdit.vue';

import DrawIO from '@/components/Drawing/drawio.vue';

export default {
  props: {
    testScenarioHeader: {
      type: Object,
      required: false,
    },
    variant: {
      type: Object,
      required: false,
    },
  },
  components: {
    TestScenarioLineSteps,
    DrawIO,
    TestScenarioEdit,
  },
  data() {
    return {
      showDialog: false,
      currentItem: null,
      key: 0,
    };
  },

  mixins: [
    makeFindMixin({
      service: 'test-scenario-line',
      watch: ['testScenarioHeader.id', 'key', 'variant.id'],
    }),
  ],

  computed: {
    testScenarioLineParams() {
      if (this.testScenarioHeader) {
        return {
          query: {
            TestScenarioHeaderId: this.testScenarioHeader.id,
            $sort: { Order: 1 },
          },
        };
      }
      if (this.variant) {
        return {
          query: {
            VariantId: this.variant.id,
            $sort: { id: 1 },
          },
        };
      }
      return { query: { id: -1 } };
    },
    headers() {
      let headersDynamic = [];

      if (this.testScenarioHeader) {
        headersDynamic = [
          {
            text: 'Order',
            align: 'start',
            sortable: false,
            value: 'Order',
            width: '50px',
          },
          {
            text: 'Number',
            align: 'start',
            sortable: false,
            value: 'id',
            width: '50px',
          },
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'Name',
          },
          {
            text: 'Process',
            align: 'start',
            sortable: false,
            value: 'variant.process.Name',
          },
          {
            text: 'Variant',
            align: 'start',
            sortable: false,
            value: 'variant.Name',
          },
          {
            text: 'Process Flow',
            align: 'center',
            groupable: false,
            sortable: false,
            value: 'flow',
          },
          {
            text: 'Steps',
            align: 'center',
            sortable: true,
            value: 'steps',
          },
          {
            text: 'Tests',
            value: 'Tests',
            align: 'center',
            sortable: true,
            groupable: false,
          },
          {
            text: 'Testable',
            align: 'center',
            sortable: false,
            value: 'Testable',
          },

          { text: 'Edit', value: 'edit' },
          { text: '', value: 'data-table-expand' },
        ];
      }
      if (this.variant) {
        headersDynamic = [
          {
            text: 'Number',
            align: 'start',
            sortable: false,
            value: 'id',
            width: '50px',
          },
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'Name',
          },
          {
            text: 'Test Header',
            align: 'left',
            groupable: true,
            sortable: true,
            value: 'test_scenario_header.Name',
          },

          {
            text: 'Tests',
            value: 'Tests',
            align: 'center',
            sortable: true,
            groupable: false,
          },
          {
            text: 'Testable',
            align: 'center',
            sortable: false,
            value: 'Testable',
          },

          { text: '', value: 'data-table-expand' },
        ];
      }

      return headersDynamic;
    },
    testScenarioLineExtended() {
      return this.addToggleColorFlag(
        this.testScenarioLine,
        'variant.ProcessId'
      );
    },
  },
  methods: {
    addToggleColorFlag(data, columnName) {
      let lastValue = null;
      let toggleFlag = false;

      return data.map((row) => {
        // Use a helper function to safely get the nested property
        const currentValue = this.getNestedProperty(row, columnName);

        // Check if the current value is different from the previous one
        if (currentValue !== lastValue) {
          toggleFlag = !toggleFlag; // Toggle the flag
          lastValue = currentValue; // Update the lastValue
        }

        // Add the new flag to the row
        return {
          ...row, // Keep all the original row data
          flag: toggleFlag, // Add the new flag column
        };
      });
    },

    // Helper function to safely access nested properties
    getNestedProperty(obj, path) {
      return path.split('.').reduce((acc, part) => {
        return acc && acc[part] !== undefined ? acc[part] : null;
      }, obj);
    },
    getRowClass(item) {
      const line = this.testScenarioLineExtended.find((f) => f.id == item.id);
      return line.flag ? 'grey-background' : '';
    },
    reload() {
      this.showDialog = false;
      this.key = this.key + 1;
      this.$forceUpdate();
    },
    EditLine(item) {
      this.currentItem = item;
      this.showDialog = true;
    },
    async reOrder(direction, item, currentIndex) {
      let list = this.testScenarioLine;

      // Check if the direction is valid
      if (direction !== 'up' && direction !== 'down') {
        console.error('Invalid direction. Use "up" or "down".');
        return;
      }

      // Check if the current index is within bounds
      if (currentIndex < 0 || currentIndex >= list.length) {
        console.error('Invalid current index.');
        return;
      }

      // Determine the target index based on the direction
      let targetIndex =
        direction === 'up' ? currentIndex - 1 : currentIndex + 1;

      // Check if the target index is within bounds
      if (targetIndex < 0 || targetIndex >= list.length) {
        console.error('Cannot move in the specified direction.');
        return;
      }

      // Swap the items in the list
      [list[currentIndex], list[targetIndex]] = [
        list[targetIndex],
        list[currentIndex],
      ];

      // Reassign Order numbers to maintain consistency and fix gaps
      list.forEach((line, index) => {
        line.Order = index + 1; // Reassign Order starting from 1
      });

      // Persist changes
      for (const line of list) {
        try {
          await line.save();
        } catch (error) {
          console.error('Error saving:', error);
        }
      }

      // Optionally, you can sort the list again (though the reordering logic should keep it sorted)
      list.sort((a, b) => a.Order - b.Order);
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.grey-background {
  background-color: #d1f5ed !important;
}
</style>
