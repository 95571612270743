var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"border-xl scaleImageMax"},[_c('v-card',{attrs:{"dense":""}},[_c('v-card-title',{staticClass:"text-h6",class:{
        TitleBackground: true,
        obsolete: _vm.step.Obsolete,
      },style:(_vm.step.GroupId != null
          ? _vm.accentStyle(_vm.group.find((f) => f.id == _vm.step.GroupId)?.allColors)
          : '')},[_vm._v(_vm._s(_vm.step.Number)+". "+_vm._s(_vm.step.Name))]),_c('v-card-text',{style:(_vm.step.GroupId != null
          ? _vm.accentStyle(_vm.group.find((f) => f.id == _vm.step.GroupId)?.allColors)
          : '')},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.step.Description)}})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }