var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showDialog && _vm.item)?_c('FeathersVuexFormWrapper',{attrs:{"item":_vm.item,"watch":""},scopedSlots:_vm._u([{key:"default",fn:function({ clone, save, reset, remove }){return [_c('v-dialog',{attrs:{"value":true,"persistent":"","max-width":"1100px"}},[_c('ProcessEditDialog',{attrs:{"item":clone,"saving":_vm.saving},on:{"save":function($event){_vm.saving = true;
          save()
            .then(async (saved) => {
              _vm.handleSaveResponse(
                saved.Name,
                'process',
                _vm.currentItem ? 'updated' : 'created'
              );
              await _vm.refresh();
              _vm.showDialog = false;
              _vm.saving = false;
            })
            .catch(async (error) => {
              _vm.handleErrorResponse(error);
              await _vm.refresh();
              _vm.saving = false;
            });},"reset":reset,"remove":function($event){remove()
            .then(async (saved) => {
              _vm.handleSaveResponse(saved.Name, 'process', 'removed');
              await _vm.refresh();
              _vm.showDialog = false;
            })
            .catch(async (error) => {
              _vm.handleErrorResponse(error);
              await _vm.refresh();
            })},"cancel":function($event){_vm.showDialog = false}}})],1)]}}],null,false,1736115179)}):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }