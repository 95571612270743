<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="testScenarioLineStep"
      :items-per-page="20"
      :sort-by="['Key']"
      :sort-desc="[false]"
      item-key="id"
      class="greenHeader secondary elevation-1"
      disable-pagination
      dense
      :hide-default-footer="true"
      :loading="isFindTestScenarioLineStepPending"
      loading-text="Loading... Please wait"
    >
      <template v-slot:[`item.Number`]="{ index }">
        {{ index + 1 }}
      </template>
      <template v-slot:[`item.color`]="{ item }">
        <span
          v-for="color in group.find((f) => f.id == item.GroupId)?.allGroups"
          :key="color.id"
          ><span v-if="color.Loop">
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="circle pointer"
                  :style="{
                    background: color.Color,
                    color: applyDark(color.Color) ? 'white' : 'black',
                  }"
                >
                  {{
                    item.Groups.find((f) => f.id == color.id)?.iteration + 1
                  }}</span
                ></template
              >{{ color.Name }}</v-tooltip
            ></span
          ><span v-else>
            <v-tooltip bottom open-delay="500">
              <template v-slot:activator="{ on, attrs }">
                <span
                  v-bind="attrs"
                  v-on="on"
                  class="circle"
                  :style="{
                    background: color.Color,
                    color: applyDark(color.Color) ? 'white' : 'black',
                  }"
                >
                  &nbsp;</span
                ></template
              >{{ color.Name }}</v-tooltip
            >
          </span>
        </span>
      </template>
      <template v-slot:[`item.Fields`]="{ item }">
        <TestScenarioLineStepField
          :keyReload="keyReload"
          :testScenarioLineStep="item"
        />
      </template>
      <template v-slot:[`item.TestRemark`]="{ item }">
        <span v-html="item.TestRemark"></span>
      </template>
      <template v-slot:[`item.process_step.status.Testable`]="{ item }">
        <v-icon v-if="item.process_step?.status.Testable" color="green"
          >mdi-check</v-icon
        >

        <v-icon v-else color="red">mdi-close</v-icon>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import TestScenarioLineStepField from './TestScenarioLineStepField';
import { applyDark } from '@/utils/Utilities';

export default {
  props: {
    testScenarioLine: {
      type: Object,
      required: true,
    },
    keyReload: {
      type: Number,
      required: true,
    },
  },
  components: { TestScenarioLineStepField },
  data() {
    return {
      selected: [],
      headers: [
        {
          text: '',
          align: 'start',
          sortable: false,
          value: 'color',
          width: '100px',
        },
        {
          text: 'Number',
          align: 'start',
          sortable: false,
          value: 'Number',
          width: '50px',
        },
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'process_step.Name',
        },
        {
          text: 'Fields',
          align: 'start',
          sortable: false,
          value: 'Fields',
        },
        {
          text: 'Test Remark',
          align: 'start',
          sortable: false,
          value: 'TestRemark',
        },
        {
          text: 'Testable',
          align: 'center',
          sortable: false,
          value: 'process_step.status.Testable',
        },
      ],
    };
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line-step',
      watch: 'keyReload',
    }),
    makeFindMixin({
      service: 'group',
    }),
  ],
  computed: {
    testScenarioLineStepParams() {
      return {
        query: { TestScenarioLineId: this.testScenarioLine.id, Selected: true },
      };
    },
    groupParams() {
      return {};
    },
  },
  methods: { applyDark },
  mounted() {},
};
</script>

<style lang="scss" scoped>
span.circle {
  // background: red;
  border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  -webkit-border-radius: 0.8em;
  color: #ffffff;
  display: inline-block;
  font-size: 10px;
  font-weight: bold;
  line-height: 1.6em;
  margin-right: 2px;
  text-align: center;
  width: 1.6em;
}

span.pointer {
  cursor: pointer;
}
</style>
