<template>
  <div v-if="!isFindTestScenarioOutputPending">
    <h3 class="mb-3">Test Outputs</h3>
    <v-row>
      <v-col
        cols="12"
        md="4"
        v-for="(output, index) in testScenarioOutput"
        :key="output.id"
      >
        <v-text-field
          v-model="Name[output.id]"
          :label="`Output Name ${index + 1}`"
          outlined
          dense
          hide-details
          @blur="updateOutputName(output)"
        >
          <template v-slot:append>
            <v-icon @click="deleteOuput(output)">mdi-delete</v-icon></template
          >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-tooltip
          bottom
          v-if="!addOutput && $can('create', 'test-scenario-output')"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              class="mx-2"
              fab
              x-small
              v-on="on"
              v-bind="attrs"
              color="primary"
              @click="addOutput = true"
            >
              <v-icon smalldark> mdi-plus </v-icon>
            </v-btn> </template
          >Add Test Output</v-tooltip
        >

        <div v-if="addOutput">
          <v-text-field
            v-model="newOutput"
            label="New Output Name"
            outlined
            dense
            @blur="addOutputName"
          >
          </v-text-field></div
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import { handleErrorResponse } from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    testScenarioLine: {
      type: Object,
      required: false,
    },
    testScenarioLineStep: {
      type: Object,
      required: false,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-output',
      watch: ['testScenarioLine.id', 'testScenarioLineStep.id'],
    }),
  ],
  watch: {
    isFindTestScenarioOutputPending: {
      handler: function () {
        if (!this.isFindTestScenarioOutputPending)
          for (const output of this.testScenarioOutput) {
            this.Name[output.id] = output.Name;
          }
      },
      deep: true,
    },
  },
  data() {
    return {
      Name: [],
      newOutput: '',
      addOutput: false,
    };
  },
  computed: {
    testScenarioOutputParams() {
      return {
        query: {
          $or: [
            { TestScenarioLineId: this.testScenarioLine?.id },
            { TestScenarioLineStepId: this.testScenarioLineStep?.id },
          ],
        },
      };
    },
  },
  methods: {
    async updateOutputName(output) {
      output.Name = this.Name[output.id];
      try {
        await output.save();
        this.$emit('update');
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    async deleteOuput(output) {
      try {
        await output.remove();
        this.$emit('update');
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    async addOutputName() {
      if (!this.newOutput?.length > 0) {
        this.addOutput = false;
        return;
      }
      const { TestScenarioOutput } = this.$FeathersVuex.api;
      const newOutput = new TestScenarioOutput();
      newOutput.Name = this.newOutput;
      if (this.testScenarioLine?.id)
        newOutput.TestScenarioLineId = this.testScenarioLine.id;
      else if (this.testScenarioLineStep?.id)
        newOutput.TestScenarioLineStepId = this.testScenarioLineStep.id;
      else return;
      try {
        const saved = await newOutput.save();
        this.newOutput = '';
        this.addOutput = false;
        this.Name[saved.id] = saved.Name;
      } catch (error) {
        handleErrorResponse(error);
      }
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" scoped></style>
