<template>
  <div v-if="allOutputs?.length > 0">
    <h3 class="mb-3">Test Inputs</h3>
    <v-row>
      <v-col cols="12" md="12"
        ><v-autocomplete
          v-if="!testScenarioLineStep"
          v-model="testScenarioLine.TestInput"
          :items="allOutputs"
          item-text="fullName"
          item-value="id"
          outlined
          dense
          hide-details
          clearable
          multiple
          small-chips
          @blur="updateTestInputsLine"
        ></v-autocomplete>
        <v-autocomplete
          v-else
          v-model="testScenarioLineStep.TestInput"
          :items="allOutputs"
          item-text="fullName"
          item-value="id"
          outlined
          dense
          hide-details
          clearable
          multiple
          small-chips
          @blur="updateTestInputsStep"
        ></v-autocomplete>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { handleErrorResponse } from '@/utils/MessageHandler';
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    testScenarioLine: {
      type: Object,
      required: false,
    },
    testScenarioLineStep: {
      type: Object,
      required: false,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line',
      name: 'testScenarioLineAll',
      watch: ['testScenarioLine.TestScenarioHeaderId'],
    }),
  ],

  data() {
    return {
      allOutputs: [],
    };
  },
  watch: {
    isFindTestScenarioLineAllPending: {
      handler: async function () {
        if (!this.isFindTestScenarioLineAllPending) {
          const { TestScenarioLineStep } = this.$FeathersVuex.api;

          let allOutputs = [];
          for (const line of this.testScenarioLineAll) {
            const steps = await TestScenarioLineStep.find({
              query: { TestScenarioLineId: line.id, $sort: { Key: 1 } },
            });
            if (steps?.total > 0) {
              for (const [index, step] of steps.data.entries()) {
                if (step.test_scenario_outputs?.length > 0)
                  for (const out of step.test_scenario_outputs) {
                    out.StepName = step.process_step.Name;
                    out.StepNumber = index + 1;
                    out.LineName = line.Name;
                    out.LineNumber = line.Order;
                    allOutputs.push(out);
                  }
              }
            }
          }

          allOutputs.push(
            this.testScenarioLineAll
              ?.map((line) => {
                for (const out of line.test_scenario_outputs) {
                  out.LineName = line.Name;
                  out.LineNumber = line.Order;
                }
                return line.test_scenario_outputs;
              })
              .flat()
          );
          allOutputs = allOutputs.flat();

          for (const out of allOutputs) {
            out.fullName = out.Name;

            if (out.StepName)
              out.fullName += ` (${out.LineNumber}.${out.StepNumber} -  ${out.LineName} / ${out.StepName})`;
            else if (out.LineName)
              out.fullName += ` (${out.LineNumber} - ${out.LineName})`;
          }

          this.allOutputs = allOutputs;
        }
      },
      deep: true,
    },
  },
  computed: {
    testScenarioLineAllParams() {
      return {
        query: {
          TestScenarioHeaderId: this.testScenarioLine.TestScenarioHeaderId,
          Order: { $lt: this.testScenarioLine.Order },
        },
      };
    },
  },
  methods: {
    async updateTestInputsLine() {
      try {
        await this.testScenarioLine.save();
        this.$emit('update');
      } catch (error) {
        handleErrorResponse(error);
      }
    },
    async updateTestInputsStep() {
      try {
        await this.testScenarioLineStep.save();
        this.$emit('update');
      } catch (error) {
        handleErrorResponse(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
