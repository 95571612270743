import { render, staticRenderFns } from "./TestScenarioInput.vue?vue&type=template&id=671ef4ab&scoped=true"
import script from "./TestScenarioInput.vue?vue&type=script&lang=js"
export * from "./TestScenarioInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "671ef4ab",
  null
  
)

export default component.exports