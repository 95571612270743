import TestRun from '@/views/TestRun.vue';

async function SyncTestRunDetail(testRunDetail, vue) {
  if (testRunDetail.AllDone === true) {
    return;
  }
  const {
    TestScenarioRunSteps,
    TestScenarioLineStep,
    TestScenarioLineStepField,
    TestScenarioRunFieldValues,
  } = vue.$FeathersVuex.api;

  const testRunSteps = await TestScenarioRunSteps.find({
    query: { TestScenarioRunDetailId: testRunDetail.id },
  });

  const testLineSteps = await TestScenarioLineStep.find({
    query: {
      TestScenarioLineId: testRunDetail.TestScenarioLineId,
      Selected: true,
    },
  });

  // Retrieve all testLineSteps where the testLineSteps.id does not exist in testRunSteps.TestScenarioLineId
  const addLineSteps = testLineSteps.data.filter(
    (lineStep) =>
      !testRunSteps.data.some(
        (runStep) => runStep.TestScenarioLineStepId === lineStep.id
      )
  );

  if (addLineSteps?.length > 0) {
    for (const step of addLineSteps) {
      let newRunStep = new TestScenarioRunSteps();
      newRunStep.Number = step.Number;
      newRunStep.Name = step.process_step.Name; //step.Name;
      newRunStep.TestRemark = step.TestRemark;
      newRunStep.ExpectedResults = step.ExpectedResults;
      newRunStep.GroupId = step.GroupId;
      newRunStep.GroupIteration = step.GroupIteration;
      newRunStep.Done = false;
      newRunStep.TestScenarioRunDetailId = testRunDetail.id;
      newRunStep.TestScenarioLineStepId = step.id;
      newRunStep.ProcessStepId = step.process_step.id;
      newRunStep.Groups = step.Groups;
      newRunStep.Key = step.Key;
      try {
        newRunStep = await newRunStep.save();
      } catch (error) {
        console.log(error);
      }
    }
  }
  // Retrieve all testRunSteps.TestScenarioLineId   does not exist in testLineSteps

  const removeLineSteps = testRunSteps.data.filter(
    (runStep) =>
      !testLineSteps.data.some(
        (lineStep) => lineStep.id === runStep.TestScenarioLineStepId
      )
  );
  if (removeLineSteps?.length > 0) {
    for (const step of removeLineSteps) {
      if (step.Done === false) {
        try {
          await step.remove();
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  // Update Steps and Fields
  for (const step of testRunSteps.data) {
    const lineStep = testLineSteps.data.find(
      (lineStep) => lineStep.id === step.TestScenarioLineStepId
    );

    if (lineStep) {
      const updatedFields = {};

      if (step.Number !== lineStep.Number) {
        updatedFields.Number = lineStep.Number;
        step.Number = lineStep.Number;
      }
      if (step.Name !== lineStep.process_step.Name) {
        updatedFields.Name = lineStep.process_step.Name;
        step.Name = lineStep.process_step.Name;
      }
      if (step.TestRemark !== lineStep.TestRemark) {
        updatedFields.TestRemark = lineStep.TestRemark;
        step.TestRemark = lineStep.TestRemark;
      }
      if (step.ExpectedResults !== lineStep.ExpectedResults) {
        updatedFields.ExpectedResults = lineStep.ExpectedResults;
        step.ExpectedResults;
      }
      if (step.GroupId !== lineStep.GroupId) {
        updatedFields.GroupId = lineStep.GroupId;
        step.GroupId = lineStep.GroupId;
      }
      if (step.GroupIteration !== lineStep.GroupIteration) {
        updatedFields.GroupIteration = lineStep.GroupIteration;
        step.GroupIteration = lineStep.GroupIteration;
      }
      if (step.ProcessStepId !== lineStep.process_step.id) {
        updatedFields.ProcessStepId = lineStep.process_step.id;
        step.ProcessStepId = lineStep.process_step.id;
      }
      if (JSON.stringify(step.Groups) !== JSON.stringify(lineStep.Groups)) {
        updatedFields.Groups = lineStep.Groups;
        step.Groups = lineStep.Groups;
      }
      if (step.Key !== lineStep.Key) {
        updatedFields.Key = lineStep.Key;
        step.Key = lineStep.Key;
      }

      if (Object.keys(updatedFields).length > 0 && step.Done === false) {
        try {
          await step.save(); //.patch(step.id, updatedFields);
        } catch (error) {
          console.log(error);
        }
      }

      // Update Fields
      const testFields = await TestScenarioLineStepField.find({
        query: { TestScenarioLineStepId: lineStep.id },
      });

      if (testFields.total > 0 && step.Done === false) {
        // Create Run Steps/Fields detail for eacht line/steps/fields

        // First remove all fields
        const fieldstoRemove = await TestScenarioRunFieldValues.find({
          query: { TestScenarioRunStepsId: step.id },
        });

        for (const field of fieldstoRemove.data) {
          try {
            await field.remove();
          } catch (error) {
            console.log(error);
          }
        }

        // Add All fields
        for (const field of testFields.data) {
          let newRunField = new TestScenarioRunFieldValues();
          newRunField.Field = field.fieldvaluelist.field.Name;
          newRunField.Name = field.fieldvaluelist.Name;
          newRunField.Code = field.fieldvaluelist.Code;
          newRunField.Name = field.fieldvaluelist.Value;
          newRunField.TestScenarioRunDetailId = testRunDetail.id;
          newRunField.TestScenarioRunStepsId = step.id;

          try {
            newRunField = await newRunField.save();
          } catch (error) {
            console.log(error);
          }
        }
      }
    }
  }
}

async function SyncTestRun(testRun, vue) {
  if (testRun.Done === true) {
    return;
  }
  const {
    TestScenarioHeader,
    TestScenarioLine,
    TestScenarioRunDetail,
    Variant,
  } = vue.$FeathersVuex.api;
  const testLine = await TestScenarioLine.find({
    query: { TestScenarioHeaderId: testRun.TestScenarioHeaderId },
  });
  const testDetail = await TestScenarioRunDetail.find({
    query: { TestScenarioRunId: testRun.id },
  });
  const testHeader = await TestScenarioHeader.find({
    query: { id: testRun.TestScenarioHeaderId },
  });

  const addLine = testLine.data.filter(
    (line) =>
      !testDetail.data.some((runStep) => runStep.TestScenarioLineId === line.id)
  );

  if (addLine?.length > 0) {
    for (const line of addLine) {
      const variant = await Variant.find({
        query: { id: line.VariantId },
      });
      let newRunDetail = new TestScenarioRunDetail();
      newRunDetail.Number = line.Number;
      newRunDetail.Name = line.Name;
      newRunDetail.TestScenarioRunId = testRun.id;
      newRunDetail.Order = line.Order;
      newRunDetail.Description = line.Description;
      newRunDetail.TestScenarioLineId = line.id;
      newRunDetail.Detailed = line.Detailed;
      newRunDetail.DepartmentId =
        variant.total > 0 ? variant.data[0].process.DepartmentId : null;

      try {
        newRunDetail = await newRunDetail.save();
      } catch (error) {
        console.log(error);
      }
    }
  }

  const removeLine = testDetail.data.filter(
    (line) =>
      !testLine.data.some((runStep) => runStep.id === line.TestScenarioLineId)
  );
  if (removeLine?.length > 0) {
    for (const line of removeLine) {
      if (line.AllDone === false) {
        try {
          await line.remove();
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  // Update test run header
  if (!testRun.Done) {
    const updatedFields = {};
    if (TestRun.Name != testHeader.data[0].Name) {
      updatedFields.Name = testHeader.data[0].Name;
      testRun.Name = testHeader.data[0].Name;
    }
    if (TestRun.Description != testHeader.data[0].Description) {
      updatedFields.Description = testHeader.data[0].Description;
      testRun.Description = testHeader.data[0].Description;
    }
    if (TestRun.TestScenarioTypeId != testHeader.data[0].TestScenarioTypeId) {
      updatedFields.TestScenarioTypeId = testHeader.data[0].TestScenarioTypeId;
      testRun.TestScenarioTypeId = testHeader.data[0].TestScenarioTypeId;
    }

    if (Object.keys(updatedFields).length > 0 && testRun.Done === false) {
      try {
        await testRun.save(); //.patch(step.id, updatedFields);
      } catch (error) {
        console.log(error);
      }
    }
  }

  // Update test run lines

  for (const line of testDetail.data) {
    const lineDetail = testLine.data.find(
      (d) => d.id === line.TestScenarioLineId
    );

    if (lineDetail) {
      const updatedFields = {};

      if (line.Order !== lineDetail.Order) {
        updatedFields.Order = lineDetail.Order;
        line.Order = lineDetail.Order;
      }
      if (line.Number !== lineDetail.Number) {
        updatedFields.Number = lineDetail.Number;
        line.Number = lineDetail.Number;
      }
      if (line.Name !== lineDetail.Name) {
        updatedFields.Name = lineDetail.Name;
        line.Name = lineDetail.Name;
      }
      if (line.Description !== lineDetail.Description) {
        updatedFields.Description = lineDetail.Description;
        line.Description = lineDetail.Description;
      }
      if (line.DepartmentId !== lineDetail.DepartmentId) {
        updatedFields.DepartmentId = lineDetail.DepartmentId;
        line.DepartmentId = lineDetail.DepartmentId;
      }
      if (line.Detailed !== lineDetail.Detailed) {
        updatedFields.Detailed = lineDetail.Detailed;
        line.Detailed = lineDetail.Detailed;
      }

      if (Object.keys(updatedFields).length > 0 && line.AllDone === false) {
        try {
          await line.save();
        } catch (error) {
          console.log(error);
        }
      }
    }

    // Update steps
    await SyncTestRunDetail(line, vue);
  }
}

export { SyncTestRunDetail, SyncTestRun };
