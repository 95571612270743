<template>
  <div>
    <!-- <ProcessSteps v-model="show" :variant="variant" /> -->

    <div class="mx-5" v-if="variant">
      <h1>{{ variant.Name }}</h1>
      <ApplicationList :variant="variant" />
      <span v-html="variant.Input"></span>
      <span v-html="variant.Output"></span>

      <div v-if="!isFindProcessStepPending && !isFindGroupPending">
        <div class="mb-4" v-for="step in processStep" :key="step.id">
          <ProcessStepCard :step="step" :group="group" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationList from '@/components/Variant/ApplicationList.vue';
import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import ProcessStepCard from '@/components/Browser/ProcessStepCard.vue';

export default {
  components: { ApplicationList, ProcessStepCard },
  data() {
    return {
      show: true,
    };
  },

  computed: {
    id() {
      return this.$store.state.activeProcessNode.VariantId;
    },
    groupParams() {
      return {
        query: {
          VariantId: this.id,
        },
      };
    },
    processStepParams() {
      return {
        query: {
          VariantId: this.id,
          $sort: { Number: 1 },
        },
      };
    },
  },
  mixins: [
    makeGetMixin({
      service: 'variant',
      id: 'id',
      watch: 'id',
    }),
    makeFindMixin({
      service: 'group',
      watch: 'id',
    }),
    makeFindMixin({
      service: 'process-step',
      watch: 'id',
    }),
  ],
};
</script>

<style lang="scss" scoped></style>
