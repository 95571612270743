<template>
  <v-expansion-panels accordion multiple>
    <v-expansion-panel>
      <v-expansion-panel-header> Field Summary </v-expansion-panel-header>
      <v-expansion-panel-content>
        <FieldSummary :testScenarioHeader="testScenarioHeader" />
      </v-expansion-panel-content>
    </v-expansion-panel>
    <v-expansion-panel>
      <v-expansion-panel-header> Output Summary </v-expansion-panel-header>
      <v-expansion-panel-content>
        <OutputSummary :testScenarioHeader="testScenarioHeader" />
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import FieldSummary from './FieldSummary.vue';
import OutputSummary from './OutputSummary.vue';
export default {
  components: {
    FieldSummary,
    OutputSummary,
  },
  props: {
    testScenarioHeader: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped></style>
