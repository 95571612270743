<template>
  <div>
    <v-progress-circular
      v-if="isPending"
      indeterminate
      :size="15"
      :width="1"
    ></v-progress-circular>
    <div v-if="!isPending && testScenarioOutput.length > 0">
      <!-- <ul>
        <li v-for="output in testScenarioOutput" :key="output.id">
          <b>{{ output.Name }} </b> ({{
            output.test_scenario_line_step?.process_step.Name ||
            output.test_scenario_line?.Name
          }}):
          <div
            v-if="
              inputStructure.filter(
                (line) =>
                  line.inputId == output.id ||
                  line.steps.some((s) => s.inputId == output.id)
              )?.length > 0
            "
          >
            <ul
              v-for="line of inputStructure.filter(
                (line) =>
                  line.inputId == output.id ||
                  line.steps.some((s) => s.inputId == output.id)
              )"
              :key="line.id"
            >
              <li>
                {{ line.Number }}. {{ line.Name }}
                <ul>
                  <li
                    v-for="step in line.steps.filter(
                      (f) => f.inputId == output.id
                    )"
                    :key="step.id"
                  >
                    {{ step.Number }}.
                    {{ step.Name }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          <v-icon small v-else color="red"> mdi-cancel </v-icon>
        </li>
      </ul> -->
      <v-data-table
        :headers="headers"
        :items="testScenarioOutput"
        :items-per-page="10"
        dense
      >
        <template v-slot:[`item.Output`]="{ item }">
          <ul v-if="item.name">
            <li>
              {{ item.name.LineNumber }}. {{ item.name.LineName }}
              <ul v-if="item.name.StepName">
                <li>{{ item.name.StepNumber }}. {{ item.name.StepName }}</li>
              </ul>
            </li>
          </ul>
        </template>
        <template v-slot:[`item.Input`]="{ item }">
          <div
            v-if="
              inputStructure.filter(
                (line) =>
                  line.inputId == item.id ||
                  line.steps.some((s) => s.inputId == item.id)
              )?.length > 0
            "
          >
            <ul
              v-for="line of inputStructure.filter(
                (line) =>
                  line.inputId == item.id ||
                  line.steps.some((s) => s.inputId == item.id)
              )"
              :key="line.id"
            >
              <li>
                {{ line.Number }}. {{ line.Name }}
                <ul>
                  <li
                    v-for="step in line.steps.filter(
                      (f) => f.inputId == item.id
                    )"
                    :key="step.id"
                  >
                    {{ step.Number }}.
                    {{ step.Name }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    testScenarioHeader: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line',
      watch: 'testScenarioHeader.id',
    }),
    makeFindMixin({
      service: 'test-scenario-line-step',
      watch: 'lineIds',
    }),
    makeFindMixin({
      service: 'test-scenario-output',
      watch: ['lineIds', 'stepIds'],
    }),
  ],
  data() {
    return {
      headers: [
        { text: 'Name', value: 'Name' },
        { text: 'Output', value: 'Output' },
        { text: 'Input', value: 'Input' },
      ],
    };
  },

  computed: {
    isPending() {
      return (
        this.isFindTestScenarioLinePending ||
        this.isFindTestScenarioLineStepPending ||
        this.isFindOuputPending
      );
    },
    testScenarioLineParams() {
      return {
        query: {
          TestScenarioHeaderId: this.testScenarioHeader.id,
          $sort: { Order: 1 },
        },
      };
    },
    lineIds() {
      return this.testScenarioLine.map((line) => line.id);
    },
    stepIds() {
      return this.testScenarioLineStep.map((step) => step.id);
    },

    testScenarioLineStepParams() {
      return {
        query: { TestScenarioLineId: { $in: this.lineIds }, $sort: { Key: 1 } },
      };
    },
    testScenarioOutputParams() {
      return {
        query: {
          $or: [
            { TestScenarioLineStepId: { $in: this.stepIds } },
            { TestScenarioLineId: { $in: this.lineIds } },
          ],
          $sort: { Name: 1 },
        },
      };
    },
    inputStructure() {
      const inputStructure = [];

      for (const line of this.testScenarioLine) {
        if (!line.TestInput) {
          const lineStructure = {
            id: line.id,
            Number: line.Order,
            Name: line.Name,
            inputId: null,
            inputName: null,
            steps: this.testScenarioLineStep
              .filter((step) => step.TestScenarioLineId === line.id)
              .map((step, index) => {
                return {
                  ...step,
                  stepIndex: index,
                };
              })
              .filter((step) => step.TestInput?.length > 0)
              ?.map((step) => {
                const steps = [];
                for (const input of step.TestInput) {
                  const stepToAdd = {
                    id: step.id,
                    Number: step.stepIndex + 1,
                    Name: step.process_step.Name,
                    inputId: input,
                    inputName: this.testScenarioOutput.find(
                      (f) => f.id == input
                    )?.Name,
                  };

                  steps.push(stepToAdd);
                }
                return steps.flat();
              })
              .flat(),
          };

          inputStructure.push(lineStructure);
        } else {
          for (const input of line.TestInput) {
            const lineStructure = {
              id: line.id,
              Number: line.Order,
              Name: line.Name,
              inputId: input,
              inputName: this.testScenarioOutput.find((f) => f.id == input)
                ?.Name,
              steps: this.testScenarioLineStep
                .filter((step) => step.TestScenarioLineId === line.id)
                .map((step, index) => {
                  return {
                    ...step,
                    stepIndex: index,
                  };
                })
                .filter((step) => step.TestInput?.length > 0)
                ?.map((step) => {
                  const steps = [];
                  for (const input of step.TestInput) {
                    const stepToAdd = {
                      id: step.id,
                      Number: step.stepIndex + 1,
                      Name: step.process_step.Name,
                      inputId: input,
                      inputName: this.testScenarioOutput.find(
                        (f) => f.id == input
                      )?.Name,
                    };

                    steps.push(stepToAdd);
                  }
                  return steps.flat();
                })
                .flat(),
            };
            inputStructure.push(lineStructure);
          }
        }
      }
      return inputStructure.filter(
        (line) => line.steps.length > 0 || line.inputId > 0
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
