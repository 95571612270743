<template>
  <div>
    <div v-if="$feature('testScenarios')">
      <v-progress-linear
        class="mt-5"
        v-if="isGetTestScenarioHeaderPending && id != 0"
        color="success"
        height="1"
        indeterminate
      ></v-progress-linear>
      <div v-if="!isGetTestScenarioHeaderPending || id == 0">
        <ejs-splitter id="default-splitter">
          <e-panes>
            <e-pane content="#planList" size="400px"></e-pane>
            <e-pane content="#schedule"></e-pane>
          </e-panes>
        </ejs-splitter>

        <!-- Pane contents -->
        <div class="ma-3 content-pane" id="planList">
          <TestScenarioPlanList
            class="content"
            @clickedTestScenario="clickedTestScenario"
            @clickedNewTestScenario="clickedNewTestScenario"
            :allowScenarioAdd="allowScenarioAdd"
            :key="refreshKey"
          />
        </div>
        <div class="ma-3 content-pane" id="schedule">
          <TestScenarioHeaderEdit
            class="content"
            v-if="testScenarioHeader || newTestScenarioHeader"
            :testScenarioHeader="testScenarioHeader || newTestScenarioHeader"
            :testScenarioToAdd="TestScenarioId"
            @reset="TestScenarioId = null"
            @enableAdd="enableAdd"
          />
        </div>
      </div>
      <TestScenarioEdit
        v-if="detailDialog"
        v-model="detailDialog"
        :currentItem="newTestLine"
        :testScenarioHeader="testScenarioHeader"
        :maxOrder="maxOrder"
      />
    </div>

    <div v-else>No access to Testscenarios.</div>
  </div>
</template>

<script>
import TestScenarioPlanList from '@/components/TestScenarios/TestScenarioBuilder/TestScenarioPlanList';
import TestScenarioHeaderEdit from '@/components/TestScenarios/TestScenarioBuilder/TestScenarioHeaderEdit';
import TestScenarioEdit from '@/components/TestScenarios/TestScenario/TestScenarioEdit.vue';
import { makeGetMixin, makeFindMixin } from 'feathers-vuex';
import { handleErrorResponse } from '@/utils/MessageHandler';
import {
  SplitterComponent,
  PanesDirective,
  PaneDirective,
} from '@syncfusion/ej2-vue-layouts';
export default {
  components: {
    TestScenarioPlanList,
    TestScenarioHeaderEdit,
    TestScenarioEdit,
    'ejs-splitter': SplitterComponent,
    'e-pane': PaneDirective,
    'e-panes': PanesDirective,
  },
  mixins: [
    makeGetMixin({
      service: 'test-scenario-header',
      id: 'id',
      watch: ['id'],
    }),
    makeFindMixin({
      service: 'test-scenario-line',
      watch: 'testScenarioHeader.id',
    }),
    makeFindMixin({
      service: 'group',
    }),
  ],
  data() {
    return {
      refreshKey: 0,
      TestScenarioId: null,
      newTestScenarioHeader: null,
      detailDialog: false,
      variantId: null,
      allowScenarioAdd: false,
      newTestLine: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    testScenarioLineParams() {
      return {
        queryWhen: this.testScenarioHeader?.id,
        query: { TestScenarioHeaderId: this.testScenarioHeader?.id },
      };
    },
    groupParams() {
      return {};
    },
    maxOrder() {
      if (!this.isFindTestScenarioLinePending) {
        return this.testScenarioLine?.reduce(
          (max, item) => (item.Order > max ? item.Order : max),
          0
        );
      } else return 0;
    },
  },
  methods: {
    // addNewTestScenario(data) {
    //   this.TestScenarioId = data.id;
    // },
    async clickedTestScenario(data) {
      this.variant = {
        id: data.VariantId,
        ProcessId: data.ProcessId,
        Name: data.name,
      };
      const {
        TestScenarioLine,
        TestScenarioLineStep,
        TestScenarioLineStepField,
      } = this.$FeathersVuex.api;
      // Get Data
      const TestScenarioLineToCopy = await TestScenarioLine.get(
        data.TestScenarioId
      );
      const TestScenarioLineStepToCopy = await TestScenarioLineStep.find({
        query: { TestScenarioLineId: TestScenarioLineToCopy.id },
      });

      // Create New Line with copied data
      let newLine = new TestScenarioLine();
      newLine.Name = TestScenarioLineToCopy.Name;
      newLine.Description = TestScenarioLineToCopy.Description;
      newLine.VariantId = TestScenarioLineToCopy.VariantId;
      newLine.TestScenarioHeaderId = this.testScenarioHeader.id;
      newLine.Order = this.maxOrder + 1;
      let savedLine = null;
      try {
        savedLine = await newLine.save();
      } catch (error) {
        handleErrorResponse(error);
      }

      if (savedLine) {
        for (const step of TestScenarioLineStepToCopy.data) {
          let newStep = new TestScenarioLineStep();
          Object.assign(newStep, step);
          newStep.TestScenarioLineId = savedLine.id;
          delete newStep.id;
          delete newStep.CreatedById;
          delete newStep.ModifiedById;
          delete newStep.createdAt;
          delete newStep.updatedAt;
          delete newStep.TestInput;
          try {
            const NewStep = await newStep.save();
            // Also add fields
            if (NewStep) {
              // Get Fields from orginal step
              const TestScenarioLineStepFieldsToCopy =
                await TestScenarioLineStepField.find({
                  query: { TestScenarioLineStepId: step.id },
                });
              // Add Fields to new step
              for (const field of TestScenarioLineStepFieldsToCopy.data) {
                let newStepField = new TestScenarioLineStepField();
                Object.assign(newStepField, field);
                newStepField.TestScenarioLineStepId = NewStep.id;
                delete newStepField.id;
                delete newStepField.CreatedById;
                delete newStepField.ModifiedById;
                delete newStepField.createdAt;
                delete newStepField.updatedAt;

                try {
                  await newStepField.save();
                } catch (error) {
                  handleErrorResponse(error);
                }
              }
            }
          } catch (error) {
            handleErrorResponse(error);
          }
          // refresh
          await TestScenarioLine.find({
            query: { TestScenarioHeaderId: this.testScenarioHeader.id },
          });
        }
      }
    },
    async clickedNewTestScenario(data) {
      this.variant = {
        id: data.VariantId,
        ProcessId: data.ProcessId,
        Name: data.Name,
      };

      const maxLevel = Math.max(
        ...this.group
          .filter((f) => f.VariantId == data.VariantId)
          .map((g) => g.allGroups.length)
      );

      // Create New Line with default Name and with all mandatory process Steps selected.
      const { TestScenarioLine, TestScenarioLineStep, ProcessStep } =
        this.$FeathersVuex.api;

      let newLine = new TestScenarioLine();
      newLine.Name = data.Name;
      newLine.VariantId = data.VariantId;
      newLine.TestScenarioHeaderId = this.testScenarioHeader.id;
      newLine.Order = this.maxOrder + 1;
      try {
        const savedLine = await newLine.save();
        this.newTestLine = await TestScenarioLine.get(savedLine.id);
        const processSteps = await ProcessStep.find({
          query: {
            VariantId: data.VariantId,
            // Mandatory: true,
            $sort: { Number: 1 },
          },
        });

        Object.assign(processSteps, this.processStep);

        // Sort the process steps by Number
        const sortedSteps = processSteps.data.sort(
          (a, b) => a.Number - b.Number
        );

        // Group by GroupId and get the first Number per group
        const firstSteps = {};
        sortedSteps.forEach((step) => {
          if (!firstSteps[step.GroupId]) {
            firstSteps[step.GroupId] = step.Number;
          }
        });

        if (processSteps.total > 0) {
          for (const [index, step] of processSteps.data.entries()) {
            const group = this.group.find((g) => g.id === step.GroupId);

            let fullGroups = [
              {
                id: 0,
                level: 0,
                firstStepNumber: 1,
                exp: maxLevel + 1,
                iteration: 0,
              },
            ];
            for (let groupIndex = 0; groupIndex < maxLevel; groupIndex++) {
              const newGroupId = group?.allGroups[groupIndex]
                ? group?.allGroups[groupIndex].id
                : 0;
              let newGroup = {
                id: newGroupId,
                level: groupIndex + 1,
                firstStepNumber: newGroupId
                  ? firstSteps[newGroupId]
                  : step.Number,
                exp: maxLevel - groupIndex,
                iteration: 0,
              };
              fullGroups.push(newGroup);
            }
            let orderCounter = 0;
            for (const group of fullGroups) {
              orderCounter =
                orderCounter +
                Math.pow(1000, group.exp) * group.firstStepNumber +
                (group.iteration * Math.pow(1000, group.exp)) / 100;
            }
            let newStep = new TestScenarioLineStep();
            newStep.Number = index + 1;
            newStep.ProcessStepId = step.id;
            newStep.TestScenarioLineId = this.newTestLine.id;
            newStep.GroupId = step.GroupId;
            newStep.Selected = step.Mandatory;
            newStep.Key = orderCounter;

            newStep.Groups = fullGroups;

            try {
              await newStep.save();
            } catch (error) {
              handleErrorResponse(error);
            }
          }
        }
      } catch (error) {
        handleErrorResponse(error);
      }

      this.detailDialog = true;
    },
    enableAdd(data) {
      this.allowScenarioAdd = data;
    },
  },
  mounted() {
    if (this.id == 0) {
      const { TestScenarioHeader } = this.$FeathersVuex.api;
      this.newTestScenarioHeader = new TestScenarioHeader();
    }
  },
};
</script>

<style lang="scss" scoped>
#default-splitter {
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent outer overflow */
}

.content-pane {
  height: calc(100vh - 90px); /* Adjust for any header or footer height */
  overflow-y: auto; /* Enable vertical scrolling only on content */
  /* Ensure proper box model */
  box-sizing: border-box;
}

#planList,
#schedule {
  padding: 10px; /* Optional: Add padding */
}

.content {
  height: 1000px; /* Example long content height */
}

/* Optional: Hide scrollbar for better aesthetics */
#planList::-webkit-scrollbar,
#schedule::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

#planList::-webkit-scrollbar-thumb,
#schedule::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Color of the scrollbar */
  border-radius: 4px; /* Rounded corners */
}

/* For Firefox */
#planList,
#schedule {
  scrollbar-width: thin; /* Use thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Thumb color and background */
}
</style>
