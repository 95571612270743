<template>
  <div width="100vw">
    <div>
      <ejs-splitter id="default-splitter" @resizeStop="onResize">
        <e-panes>
          <e-pane content="#menu" size="15%">Koekoek</e-pane>
          <e-pane content="#drawing" size="40%">Koekoek</e-pane>
          <e-pane content="#nested"></e-pane>
        </e-panes>
      </ejs-splitter>
    </div>

    <!-- pane contents -->
    <div id="menu" class="content-pane"><ProcessListTree /></div>
    <div id="drawing" class="mx-5">
      <div
        v-if="
          $store.state.activeProcessNode.TestScenarioHeaderId &&
          !$store.state.activeProcessNode.TestScenarioLineId
        "
      >
        <h3>Test Scenario Flow</h3>
        <DrawIO
          :key="drawIoKey"
          :testScenarioHeaderId="
            parseInt($store.state.activeProcessNode.TestScenarioHeaderId)
          "
          height="45vh"
        />
      </div>
      <div
        v-if="
          // !$store.state.activeProcessNode.VariantId &&
          $store.state.activeProcessNode.ProcessId
        "
      >
        <h3>
          Process Flow <span v-if="process?.Name">- {{ process?.Name }}</span>
        </h3>
        <DrawIO
          :key="drawIoKey + 2"
          :processId="parseInt($store.state.activeProcessNode.ProcessId)"
          :highlightVariantId="
            parseInt($store.state.activeProcessNode.VariantId)
          "
          height="45vh"
        />
      </div>
      <div v-if="$store.state.activeProcessNode.VariantId">
        <hr />
        <h3>
          Sub Process Flow
          <span v-if="variant?.Name">- {{ variant?.Name }}</span>
        </h3>
        <DrawIO
          :key="drawIoKey + 1"
          :variantId="parseInt($store.state.activeProcessNode.VariantId)"
          height="45vh"
        />
      </div>
    </div>
    <div class="sticky" id="nested">
      <ejs-splitter orientation="Vertical" @resizeStop="onResize">
        <e-panes>
          <e-pane content="#breadcrumb" size="40px" :resizable="false"></e-pane>
          <e-pane content="#content"></e-pane>
        </e-panes>
      </ejs-splitter>
    </div>

    <div id="breadcrumb"><Breadcrumb /></div>
    <div id="content" class="content-pane">
      <TestLineBrowser
        v-if="$store.state.activeProcessNode.TestScenarioLineId"
      />
      <VariantBrowser
        v-if="
          $store.state.activeProcessNode.ProcessId &&
          !$store.state.activeProcessNode.VariantId &&
          !$store.state.activeProcessNode.TestScenarioLineId
        "
      />
      <ProcessStepBrowser
        v-if="
          $store.state.activeProcessNode.VariantId &&
          !$store.state.activeProcessNode.TestScenarioLineId
        "
      />
    </div>
  </div>
</template>

<script>
import ProcessListTree from '@/components/Browser/ProcessListTree';
import VariantBrowser from '@/components/Browser/VariantBrowser';
import ProcessStepBrowser from '@/components/Browser/ProcessStepBrowser';
import TestLineBrowser from '@/components/Browser/TestLineBrowser.vue';
import Breadcrumb from '@/components/Browser/Breadcrumb.vue';
import DrawIO from '@/components/Drawing/drawio.vue';

import {
  SplitterComponent,
  PanesDirective,
  PaneDirective,
} from '@syncfusion/ej2-vue-layouts';
import feathersClient from '@/feathers-client';
import { mapActions } from 'vuex';

export default {
  components: {
    ProcessListTree,
    VariantBrowser,
    ProcessStepBrowser,
    TestLineBrowser,
    Breadcrumb,
    DrawIO,
    'ejs-splitter': SplitterComponent,
    'e-pane': PaneDirective,
    'e-panes': PanesDirective,
  },
  data() {
    return {
      drawIoKey: 0,
      process: null,
      variant: null,
    };
  },
  computed: {
    processId() {
      return this.$store.state.activeProcessNode.ProcessId;
    },
    variantId() {
      return this.$store.state.activeProcessNode.VariantId;
    },
  },
  watch: {
    '$route.query.processId': {
      handler(newVal) {
        this.setActiveProcessNode({
          ...this.$store.state.activeProcessNode,
          ProcessId: parseInt(newVal),
        });
      },
      immediate: true,
      deep: true,
    },
    '$route.query.variantId': {
      handler(newVal) {
        this.setActiveProcessNode({
          ...this.$store.state.activeProcessNode,
          VariantId: parseInt(newVal),
        });
      },
      immediate: true,
      deep: true,
    },
    '$route.query.GroupedId': {
      handler(newVal) {
        this.setActiveProcessNode({
          ...this.$store.state.activeProcessNode,
          End2EndId: parseInt(newVal),
        });
      },
      immediate: true,
      deep: true,
    },
    '$route.query.testScenarioHeaderId': {
      handler(newVal) {
        this.setActiveProcessNode({
          ...this.$store.state.activeProcessNode,
          TestScenarioHeaderId: parseInt(newVal),
        });
      },
      immediate: true,
      deep: true,
    },
    '$route.query.testScenarioLineId': {
      handler(newVal) {
        this.setActiveProcessNode({
          ...this.$store.state.activeProcessNode,
          TestScenarioLineId: parseInt(newVal),
        });
      },
      immediate: true,
      deep: true,
    },
    '$route.query.Type': {
      handler(newVal) {
        this.setActiveProcessNode({
          ...this.$store.state.activeProcessNode,
          Type: newVal,
        });
      },
      immediate: true,
      deep: true,
    },

    async processId() {
      if (this.processId) {
        this.process = await feathersClient
          .service('process')
          .get(this.processId);
      }
    },
    async variantId() {
      if (this.variantId) {
        this.variant = await feathersClient
          .service('variant')
          .get(this.variantId);
      }
    },
  },
  methods: {
    ...mapActions(['setActiveProcessNode']),
    onResize() {
      this.drawIoKey += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
#default-splitter {
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Prevent outer overflow */
}

.content-pane {
  height: calc(100vh - 90px); /* Adjust for any header or footer height */
  overflow-y: auto; /* Enable vertical scrolling only on content */
  /* Ensure proper box model */
  box-sizing: border-box;
}

#menu,
#schedule {
  padding: 10px; /* Optional: Add padding */
}

.content {
  height: 1000px; /* Example long content height */
}

/* Optional: Hide scrollbar for better aesthetics */
#menu::-webkit-scrollbar,
#schedule::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

#menu::-webkit-scrollbar-thumb,
#schedule::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5); /* Color of the scrollbar */
  border-radius: 4px; /* Rounded corners */
}

/* For Firefox */
#menu,
#schedule {
  scrollbar-width: thin; /* Use thin scrollbar */
  scrollbar-color: rgba(0, 0, 0, 0.5) transparent; /* Thumb color and background */
}
</style>
