<template>
  <div class="mx-10">
    <h1>Global search - '{{ search }}'</h1>

    <v-expansion-panels>
      <v-expansion-panel key="1">
        <v-expansion-panel-header>
          <h3>Process ({{ highlightedItemsProcess?.length }})</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            v-if="highlightedItemsProcess"
            :headers="exportQueriesHeadersProcess"
            :items="highlightedItemsProcess"
            row-class="align-top"
          >
            <template v-slot:[`item.ProcessName`]="{ item }">
              <a
                :href="`/#/processdetail/${item.ProcessId}&${item.ProcessNumber}`"
                ><span v-html="item.ProcessName"></span
              ></a>
            </template>

            <template v-slot:[`item.ProcessInput`]="{ item }">
              <span v-html="item.ProcessInput"></span
            ></template>
            <template v-slot:[`item.ProcessOutput`]="{ item }">
              <span v-html="item.ProcessOutput"></span
            ></template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="2">
        <v-expansion-panel-header>
          <h3>Variant ({{ highlightedItemsVariant?.length }})</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            v-if="highlightedItemsVariant"
            :headers="exportQueriesHeadersVariant"
            :items="highlightedItemsVariant"
            row-class="align-top"
          >
            <template v-slot:[`item.ProcessName`]="{ item }">
              <a
                :href="`/#/processdetail/${item.ProcessId}&${item.ProcessNumber}`"
                ><span v-html="item.ProcessName"></span
              ></a>
            </template>
            <template v-slot:[`item.VariantName`]="{ item }">
              <a :href="`/#/processstep/${item.VariantId}`"
                ><span v-html="item.VariantName"></span></a
            ></template>
            <template v-slot:[`item.VariantInput`]="{ item }">
              <span v-html="item.VariantInput"></span
            ></template>
            <template v-slot:[`item.VariantOutput`]="{ item }">
              <span v-html="item.VariantOutput"></span
            ></template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="3">
        <v-expansion-panel-header>
          <h3>Process Steps ({{ highlightedItemsProcessStep?.length }})</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-data-table
            v-if="highlightedItemsProcessStep"
            :headers="exportQueriesHeadersProcessStep"
            :items="highlightedItemsProcessStep"
            row-class="align-top"
          >
            <template v-slot:[`item.ProcessName`]="{ item }">
              <a
                :href="`/#/processdetail/${item.ProcessId}&${item.ProcessNumber}`"
                ><span v-html="item.ProcessName"></span
              ></a>
            </template>
            <template v-slot:[`item.VariantName`]="{ item }">
              <a :href="`/#/processstep/${item.VariantId}`"
                ><span v-html="item.VariantName"></span></a
            ></template>
            <template v-slot:[`item.ProcessStepName`]="{ item }">
              <span v-html="item.ProcessStepName"></span
            ></template>
            <template v-slot:[`item.ProcessStepDescription`]="{ item }">
              <span
                class="smallerImages"
                v-html="item.ProcessStepDescription"
              ></span
            ></template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel key="4">
        <v-expansion-panel-header>
          <h3>
            Issues ({{ highlightedItemsIssues?.length }})
          </h3></v-expansion-panel-header
        >
        <v-expansion-panel-content>
          <v-data-table
            v-if="highlightedItemsIssues"
            :headers="issueSearchHeaders"
            :items="highlightedItemsIssues"
            row-class="align-top"
          >
            <template v-slot:[`item.IssueExternalId`]="{ item }">
              <span v-html="item.IssueExternalId"></span
            ></template>
            <template v-slot:[`item.IssueTitle`]="{ item }">
              <span v-html="item.IssueTitle"></span
            ></template>
            <template v-slot:[`item.IssueDescription`]="{ item }">
              <span class="smallerImages" v-html="item.IssueDescription"></span
            ></template>
            <template v-slot:[`item.IssueDescription2`]="{ item }">
              <span class="smallerImages" v-html="item.IssueDescription2"></span
            ></template>
            <template v-slot:[`item.Status`]="{ item }">
              <v-chip
                class="ma-2"
                :color="item.Color"
                x-small
                :text-color="applyDark(item.Color) ? 'white' : 'black'"
              >
                {{ item.Status }}
              </v-chip>
            </template>
          </v-data-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { applyDark } from '@/utils/Utilities';
export default {
  mixins: [
    makeFindMixin({
      service: 'export-queries',
      watch: 'search',
      name: 'processSearch',
    }),
    makeFindMixin({
      service: 'export-queries',
      watch: 'search',
      name: 'variantSearch',
    }),
    makeFindMixin({
      service: 'export-queries',
      watch: 'search',
      name: 'processStepSearch',
    }),
    makeFindMixin({
      service: 'export-queries',
      name: 'issueSearch',
      watch: 'search',
    }),
  ],
  computed: {
    search() {
      return this.$route.params.search;
    },
    processSearchParams() {
      return { query: { type: 'globalSearchProcess', search: this.search } };
    },
    variantSearchParams() {
      return { query: { type: 'globalSearchVariant', search: this.search } };
    },
    processStepSearchParams() {
      return {
        query: { type: 'globalSearchProcessStep', search: this.search },
      };
    },
    issueSearchParams() {
      return { query: { type: 'globalSearchIssue', search: this.search } };
    },

    highlightedItemsProcess() {
      return this.processSearchLatestQuery?.response.map((item) => {
        const newItem = { ...item };
        Object.keys(newItem).forEach((key) => {
          newItem[key] = this.highlightText(newItem[key]);
        });
        return newItem;
      });
    },
    highlightedItemsVariant() {
      return this.variantSearchLatestQuery?.response.map((item) => {
        const newItem = { ...item };
        Object.keys(newItem).forEach((key) => {
          newItem[key] = this.highlightText(newItem[key]);
        });
        return newItem;
      });
    },
    highlightedItemsProcessStep() {
      return this.processStepSearchLatestQuery?.response.map((item) => {
        const newItem = { ...item };
        Object.keys(newItem).forEach((key) => {
          newItem[key] = this.highlightText(newItem[key]);
        });
        return newItem;
      });
    },
    highlightedItemsIssues() {
      return this.issueSearchLatestQuery?.response.map((item) => {
        const newItem = { ...item };
        Object.keys(newItem).forEach((key) => {
          newItem[key] = this.highlightText(newItem[key]);
        });
        return newItem;
      });
    },
  },
  methods: {
    applyDark,
    highlightText(text) {
      if (!this.search) return text;
      const regex = new RegExp(`(${this.search})`, 'gi');
      return text?.toString().replace(regex, '<mark>$1</mark>');
    },
  },
  data() {
    return {
      exportQueriesHeadersProcess: [
        { text: 'Process Name', value: 'ProcessName', cellClass: 'topalign' },
        { text: 'Process Input', value: 'ProcessInput', cellClass: 'topalign' },
        {
          text: 'Process Output',
          value: 'ProcessOutput',
          cellClass: 'topalign',
        },
      ],
      exportQueriesHeadersVariant: [
        { text: 'Process Name', value: 'ProcessName', cellClass: 'topalign' },
        { text: 'Variant Name', value: 'VariantName', cellClass: 'topalign' },
        { text: 'Variant Input', value: 'VariantInput', cellClass: 'topalign' },
        {
          text: 'Variant Output',
          value: 'VariantOutput',
          cellClass: 'topalign',
        },
      ],
      exportQueriesHeadersProcessStep: [
        { text: 'Process Name', value: 'ProcessName', cellClass: 'topalign' },
        { text: 'Variant Name', value: 'VariantName', cellClass: 'topalign' },
        {
          text: 'Process Step Name',
          value: 'ProcessStepName',
          cellClass: 'topalign',
        },
        {
          text: 'Process Step Description',
          value: 'ProcessStepDescription',
          cellClass: 'topalign',
        },
      ],
      issueSearchHeaders: [
        { text: 'ExternalId', value: 'IssueExternalId', cellClass: 'topalign' },

        { text: 'Issue Title', value: 'IssueTitle', cellClass: 'topalign' },
        {
          text: 'Issue Description',
          value: 'IssueDescription',
          cellClass: 'topalign',
        },
        {
          text: 'Issue Description 2',
          value: 'IssueDescription2',
          cellClass: 'topalign',
        },
        {
          text: 'Issue Status',
          value: 'Status',
          cellClass: 'topalign',
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.smallerImages img {
  max-width: 200px !important;
  max-height: 200px !important;
}

mark {
  background-color: #caf600ff;

  color: black;
}
</style>
