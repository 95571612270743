<template>
  <div>
    <h1>
      {{ process.Name }}
      <v-icon
        v-if="$can('update', 'process')"
        small
        class="mr-2"
        @click="clickEdit(item)"
        color="primary"
      >
        mdi-pencil
      </v-icon>

      <v-icon
        v-if="$can('update', 'variant')"
        small
        class="mr-2"
        @click="updateStatus()"
        color="primary"
      >
        mdi-update
      </v-icon>
    </h1>
    <v-row class="ma-0 pa-0"
      ><v-card flat v-if="process.end_2_ends?.length > 0" class="mx-5"
        ><v-card-title class="text-subtitle-1 pb-1 pl-0 pt-0"
          >End 2 Ends</v-card-title
        >
        <ul>
          <li
            class="text-body-2 ma-0 pa-0"
            v-for="(e, index) in process.end_2_ends
              .slice()
              .sort((a, b) => a.Number - b.Number)"
            :key="index"
          >
            {{ e.Number }}. <a :href="'/#/process/' + e.id">{{ e.Name }}</a>
          </li>
        </ul></v-card
      ></v-row
    >
    <v-row class="ma-0 pa-0"
      ><v-card flat v-if="process.Input" class="mx-5"
        ><v-card-title class="text-subtitle-1 pb-1 pl-0 pt-0"
          >Input</v-card-title
        >
        <span
          v-html="process.Input"
          class="text-body-2 ma-0 pa-0 ql-editor"
        ></span></v-card
    ></v-row>
    <v-row class="ma-0 pa-0"
      ><v-card flat v-if="process.Output" class="mx-5"
        ><v-card-title class="text-subtitle-1 pb-1 pl-0">Output</v-card-title>
        <span
          v-html="process.Output"
          class="text-body-2 ma-0 pa-0 ql-editor"
        ></span></v-card
    ></v-row>
    <div v-if="$feature('drawIOChartIntegration')">
      <v-row>
        <v-col class="mt-5 mb-10"><DrawIO :processId="process.id" /></v-col
      ></v-row>
    </div>
    <v-row></v-row>
    <VariantList
      v-if="process"
      :variant="process.variants"
      :process="process"
      @show="showDetails(arguments, item)"
    />
  </div>
</template>

<script>
import { makeGetMixin } from 'feathers-vuex';
import DrawIO from '@/components/Drawing/drawio.vue';
import VariantList from '@/components/Variant/VariantList.vue';

export default {
  components: {
    DrawIO,
    VariantList,
  },
  mixins: [
    makeGetMixin({
      service: 'process',
      id: 'processId',
      watch: 'processId',
    }),
  ],
  computed: {
    processId() {
      return this.$store.state.activeProcessNode.ProcessId;
    },
  },
};
</script>

<style lang="scss" scoped></style>
