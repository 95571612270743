<template>
  <div>
    <v-dialog :value="true" persistent max-width="1100px">
      <v-progress-linear
        v-if="isFindStatusPending"
        indeterminate
        color="secondary"
      ></v-progress-linear>

      <div v-if="item && !isFindStatusPending">
        <v-form ref="form" v-model="validInput">
          <v-card flat>
            <v-card-title>
              <span class="headline">New/Edit Process Step</span>
            </v-card-title>

            <v-card-text>
              <v-row>
                <v-col cols="12" md="1">
                  <v-text-field
                    v-model="item['Number']"
                    label="Nr"
                    outlined
                    :disabled="!$can('update', item, 'Number')"
                    dense
                    :rules="[rules.required, rules.number]"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="5">
                  <v-text-field
                    v-model="item['Name']"
                    label="Process Step Name"
                    :rules="[rules.required]"
                    :disabled="!$can('update', item, 'Name')"
                    outlined
                    dense
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="item['PriorityId']"
                    :items="priority"
                    item-text="Name"
                    item-value="id"
                    label="Prioriteit"
                    :disabled="!$can('update', item, 'PriorityId')"
                    outlined
                    :rules="[rules.required]"
                    dense
                  ></v-select>
                </v-col>
                <v-col cols="12" md="3">
                  <v-select
                    v-model="item['StatusId']"
                    :items="filteredStatus"
                    item-text="Status"
                    item-value="id"
                    label="Status"
                    outlined
                    :disabled="!$can('update', item, 'StatusId')"
                    :rules="[rules.required]"
                    dense
                  >
                    <template v-slot:item="{ item }">
                      <v-icon small class="mr-2" :color="item.Color">
                        mdi-circle
                      </v-icon>
                      {{ item.Status }} </template
                    ><template #selection="{ item }">
                      <v-icon small class="mr-2" :color="item.Color">
                        mdi-circle
                      </v-icon>
                      {{ item.Status }}
                    </template></v-select
                  >
                </v-col>
                <v-col cols="12" md="12">
                  Process Step<RichEditor
                    v-model="item.Description"
                    :disabled="!$can('update', item, 'Description')"
                    placeholder="Process step description"
                    @busy="isBusy"
                  ></RichEditor>
                </v-col>
              </v-row>
              <v-row
                ><v-col>
                  <v-checkbox
                    v-model="item.Mandatory"
                    :disabled="!$can('update', item, 'Mandatory')"
                    :label="`Mandatory Step`"
                  ></v-checkbox></v-col
                ><v-col>
                  <v-checkbox
                    v-model="item.Obsolete"
                    :label="`Obsolete`"
                  ></v-checkbox></v-col
              ></v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                v-if="$can('delete', item)"
                outlined
                @click="showRemoveMessage = true"
                >Remove</v-btn
              >
              <div v-if="showRemoveMessage" class="ml-5">
                Are you sure?
                <v-btn
                  class="ml-2"
                  @click="
                    item.Deleted = true;
                    item.Obsolete = true; // set also to obsolete to make sure statistics are correct
                    $emit('save');
                  "
                  color="error"
                  >Yes</v-btn
                >
              </div>
              <v-spacer></v-spacer>
              <v-btn outlined @click="$emit('cancel')">Cancel</v-btn>
              <v-btn
                :disabled="!validInput"
                color="primary"
                :loading="saving || isUploadingImage"
                @click="$emit('save')"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import { mapGetters } from 'vuex';
import RichEditor from '@/components/general/RichEditor.vue';

export default {
  name: 'ProcessStepEditDialog',
  components: {
    RichEditor,
  },
  data() {
    return {
      prioriteit: ['Must', 'Should'],
      validInput: true,
      errorMessage: null,
      showRemoveMessage: false,

      rules: {
        required: (value) => !!value || 'Required.',
        counter: (value) => value.length <= 20 || 'Max 20 characters',
        number: (value) => !isNaN(value) || 'Must be a number',
      },
      isUploadingImage: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    saving: { type: Boolean },
  },
  mixins: [
    makeFindMixin({ service: 'status' }),
    makeFindMixin({ service: 'priority' }),
  ],
  methods: {
    isBusy(status) {
      this.isUploadingImage = status;
    },
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    statusParams() {
      return { query: { Active: true, $sort: { Number: 1 } } };
    },
    filteredStatus() {
      if (this.user.role == 'customer')
        return this.status.filter((s) => s.Role == this.user.role);
      else return this.status;
    },
    priorityParams() {
      return {};
    },
  },
};
</script>
