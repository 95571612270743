<template>
  <div>
    <qrcode :value="value" :options="{ width: width }"></qrcode>
  </div>
</template>

<script>
export default {
  name: 'ShowQrCode',
  props: {
    value: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 200,
    },
  },
};
</script>

<style lang="scss" scoped></style>
