<template>
  <!-- <v-dialog :value="true" persistent max-width="800px"> -->
  <v-card flat dense class="mt-5">
    <draggable v-model="list" v-bind="dragOptions">
      <transition-group>
        <v-card elevation="1" v-for="item in list" :key="item.id" class="mb-2">
          <v-card-text class="py-0">
            {{ item.Order }}.
            {{ item.variant.process.Number.toString().padStart(4, '0') }}.{{
              item.variant.Number.toString().padStart(2, '0')
            }}
            - {{ item.Name }}"
          </v-card-text>
        </v-card>
      </transition-group>
    </draggable>
    <v-card-actions
      ><v-spacer></v-spacer>
      <v-btn outlined @click="showReOrderDialog = false">Cancel</v-btn>
      <v-btn color="primary" @click="saveLines()"> Save </v-btn>
    </v-card-actions>
  </v-card>
  <!-- </v-dialog> -->
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
import feathersClient from '@/feathers-client';
import draggable from 'vuedraggable';
export default {
  props: ['testScenarioHeader'],
  name: 'Reorder',
  data() {
    return {
      list: [],
    };
  },
  components: { draggable },
  mixins: [
    makeFindMixin({
      service: 'test-scenario-line',
      watch: 'testScenarioHeader.id',
    }),
  ],
  watch: {
    isFindTestSceanrioLinePending: {
      handler() {
        if (!this.isFindTestSceanrioLinePending) {
          this.list = [...this.testScenarioLine];
        }
      },
      immediate: true,
    },
  },

  methods: {
    saveLines() {
      this.list.forEach((p, index) => {
        feathersClient
          .service('test-scenario-line')
          .patch(p.id, { Order: index + 1 });
      });
      this.showReOrderDialog = false;
    },
  },
  computed: {
    testScenarioLineParams() {
      return {
        query: {
          TestScenarioHeaderId: this.testScenarioHeader.id,
          $sort: { Order: 1 },
        },
      };
    },
    showReOrderDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },
};
</script>

<style></style>
