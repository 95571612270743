// src/store/index.js
import Vue from 'vue';
import Vuex from 'vuex';
import { FeathersVuex } from '../feathers-client';
import auth from './store.auth';
import { caslPlugin } from '@/store/vuex.plugin.casl'; // your previously defined file

Vue.use(Vuex);
Vue.use(FeathersVuex);

const requireModule = require.context(
  // The path where the service modules live
  './services',
  // Whether to look in subfolders
  false,
  // Only include .js files (prevents duplicate imports`)
  /\.js$/
);
const servicePlugins = requireModule
  .keys()
  .map((modulePath) => requireModule(modulePath).default);

export default new Vuex.Store({
  state: {
    currentProcess: {},
    activeProcessNode: {
      Type: null,
      End2EndId: null,
      ProcessId: null,
      VariantId: null,
      TestScenarioHeaderId: null,
      TestScenarioLineId: null,
    },
    globalSettings: {},
    activeEnd2End: {},
    drawer: false,
    drawioTemplateUrl: null,
    packageVersion: process.env.PACKAGE_VERSION || '0',
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion;
    },
  },
  mutations: {
    updateactiveProcessNode(state, node) {
      state.activeProcessNode = node;
    },
    updateCurrentProcess(state, process) {
      state.currentProcess = process;
    },
    setActiveEnd2End(state, end2end) {
      state.activeEnd2End = end2end;
    },
    updateDrawioTemplateUrl(state, url) {
      state.drawioTemplateUrl = url;
    },
    updateSettings(state, settings) {
      state.globalSettings = settings;
    },
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
  },
  actions: {
    async setCurrentProcess({ commit }, process) {
      commit('updateCurrentProcess', process);
    },
    async setDrawioTemplateUrl({ commit }, url) {
      commit('updateDrawioTemplateUrl', url);
    },
    async setActiveProcessNode({ commit }, node) {
      commit('updateactiveProcessNode', node);
    },
    async setSettings({ commit }, settings) {
      commit('updateSettings', settings);
    },
  },
  plugins: [...servicePlugins, auth, caslPlugin],
});
