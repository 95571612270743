<template>
  <v-container class="mt-10">
    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-card class="justify-center">
          <v-card-title class="justify-center">
            {{ $t('title.login') }}
          </v-card-title>
          <v-card-text class="justify-center">
            <v-row justify="center">
              <v-col cols="12" md="8">
                <form @submit.prevent="submit">
                  <v-text-field
                    v-model="login.email"
                    :error-messages="emailErrors"
                    :label="$t('form.email')"
                    required
                    autofocus
                  ></v-text-field>
                  <v-text-field
                    v-model="login.password"
                    :label="$t('form.password')"
                    type="password"
                    required
                  ></v-text-field>
                  <v-select
                    v-model="$i18n.locale"
                    :items="langs"
                    item-text="Language"
                    item-value="Code"
                    :label="$t('form.language')"
                  ></v-select>
                  <v-card-actions class="mt-5 justify-center">
                    <v-btn @click="clear" outlined>
                      {{ $t('button.clear') }}
                    </v-btn>
                    <v-btn type="submit" class="mr-4" color="primary">
                      {{ $t('button.login') }}
                    </v-btn>
                  </v-card-actions>
                </form>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <Agreement
      v-if="ShowAgreement"
      @cancel="CancelAgreement"
      @agree="AcceptAgreement"
    />
    <v-snackbar v-model="errorShow" timeout="2000">
      {{ errorMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="errorShow = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mapGetters, mapActions } from 'vuex';
import Agreement from '@/components/Agreement.vue';
import feathersClient from '@/feathers-client';
import md5 from 'blueimp-md5';

export default {
  components: { Agreement },
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required },
  },
  data() {
    return {
      ShowAgreement: false,
      login: {
        email: '',
        password: '',
        strategy: 'local',
      },
      errorMessage: '',
      errorShow: false,
      langs: [
        { Language: 'English', Code: 'en' },
        { Language: 'Nederlands', Code: 'nl' },
        { Language: 'Français', Code: 'fr' },
      ],
      isAuthenticating: false, // Flag to prevent multiple submissions
    };
  },
  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Must be valid e-mail');
      !this.$v.email.required && errors.push('E-mail is required');
      return errors;
    },
    ...mapGetters({ user: 'auth/user', authenticated: 'auth/isAuthenticated' }),
  },
  methods: {
    ...mapActions(['setSettings']),
    CancelAgreement() {
      this.ShowAgreement = false;
      this.$store.dispatch('auth/logout');
      this.clear();
    },
    AcceptAgreement() {
      // Save agreement date
      feathersClient
        .service('users')
        .patch(this.user.id, { agreementAcceptedDate: new Date() })
        .then(() => this.$router.push('/'))
        .catch((error) => console.log(error));
    },
    async checkAgreement() {
      if (!this.user.agreementAcceptedDate && this.user.Active) {
        this.ShowAgreement = true;
      } else if (!this.user.Active) {
        this.CancelAgreement();
      } else {
        // Redirect to the process page only if the agreement is accepted
        this.$router.push('/process');
      }
    },
    async updateGravatarUrl() {
      let url = null;
      let hash = md5(this.login.email.toLowerCase().trim());
      url = `https://www.gravatar.com/avatar/${hash}.jpg?s=80&d=404`;
      var http = new XMLHttpRequest();
      http.open('HEAD', url, false);
      http.send();
      if (http.status != 404 && this.user.agreementAcceptedDate) {
        feathersClient
          .service('users')
          .patch(this.user.id, { gravatarUrl: url })
          .then(() => this.$router.push('/')) // Navigate after updating
          .catch((error) => console.log(error));
      }
    },
    async submit() {
      if (this.isAuthenticating) return; // Prevent multiple submissions
      this.isAuthenticating = true; // Set the flag

      try {
        this.login.email = this.login.email.toLowerCase().trim();
        await this.$store.dispatch('auth/authenticate', this.login);

        // Check agreement after successful authentication
        await this.checkAgreement();
        await this.updateGravatarUrl(); // Optional: You might want to navigate after this
        // Load Settings
        const settings = await feathersClient.service('settings').find();
        if (settings?.total > 0) {
          this.setSettings(settings.data);
        }
      } catch (error) {
        this.errorMessage = error.message;
        this.errorShow = true;
      } finally {
        this.isAuthenticating = false; // Reset the flag
      }
    },
    clear() {
      this.$v.$reset();
      this.login.email = '';
      this.login.password = '';
    },
  },
  mounted() {
    this.login.email = this.$route.query.email;
  },
};
</script>

<style lang="scss" scoped></style>
