<template>
  <div>
    <v-alert
      v-if="erpapplicationVariant?.length > 0"
      color="#eeeeee"
      class="my-2"
    >
      <h4>Application</h4>
      <v-list
        dense
        class="pl-0 ml-0 py-0 my-0"
        style="background-color: transparent !important"
      >
        <v-list-item v-for="app in erpapplicationVariant" :key="app.id">
          <v-list-item-content class="my-0 py-0">
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-title v-bind="attrs" v-on="on">
                  <a
                    v-if="app.erp_application.Link"
                    :href="app.erp_application.Link"
                    target="_blank"
                  >
                    <span v-if="app.erp_application.Customized">*</span>
                    {{ app.erp_application.Name }}
                  </a>
                  <span v-else>{{ app.erp_application.Name }}</span>
                </v-list-item-title>
              </template>
              {{ app.erp_application.Name }}
            </v-tooltip>
            <v-tooltip top open-delay="1000">
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-subtitle v-bind="attrs" v-on="on">
                  {{ app.erp_application.Filter }}
                </v-list-item-subtitle>
              </template>
              {{ app.erp_application.Filter }}
            </v-tooltip>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-alert>
  </div>
</template>

<script>
import { makeFindMixin } from 'feathers-vuex';
export default {
  props: {
    variant: {
      type: Object,
      required: true,
    },
  },
  mixins: [
    makeFindMixin({
      service: 'erpapplication-variant',
      watch: 'variant.id',
    }),
  ],
  computed: {
    erpapplicationVariantParams() {
      return {
        query: {
          VariantId: this.variant.id,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
