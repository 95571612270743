<template>
  <div class="border-xl scaleImageMax">
    <v-card dense>
      <v-card-title
        class="text-h6"
        :class="{
          TitleBackground: true,
          obsolete: step.process_step.Obsolete,
        }"
        :style="group ? accentStyle(group.allColors) : ''"
        >{{ step.Number }}. {{ step.process_step.Name }}</v-card-title
      >
      <v-card-text :style="group ? accentStyle(group.allColors) : ''"
        >.
        <v-alert class="text-caption" dense color="secondary">
          <b>Test Remark</b>: <span v-html="step.TestRemark"></span>
          <TestLineFieldBrowser :testScenarioLineStep="step" />
        </v-alert>
        <span v-html="step.process_step.Description"></span
      ></v-card-text>
    </v-card>
  </div>
</template>

<script>
import TestLineFieldBrowser from './TestLineFieldBrowser.vue';
import { makeGetMixin } from 'feathers-vuex';

export default {
  components: {
    TestLineFieldBrowser,
  },
  mixins: [
    makeGetMixin({
      service: 'group',
      id: 'GroupId',
      watch: 'GroupId',
    }),
  ],
  props: {
    step: {
      type: Object,
      required: true,
    },
  },
  computed: {
    GroupId() {
      return this.step.GroupId;
    },
  },
  methods: {
    accentStyle(colors) {
      let boxShadow = '';

      let groupColors = [...colors];
      groupColors.reverse();
      // Start looping from the second color (index 1)
      groupColors.slice(1).forEach((color, index) => {
        const offset = (index + 1) * -5; // Calculate the offset for each color
        boxShadow += `${offset}px 0 0 0 ${color}, `;
      });

      // Remove the trailing comma and space from the last box-shadow entry
      boxShadow = boxShadow.slice(0, -2);

      const style = {
        'border-left': `5px solid ${groupColors[0]}`, // First border with the first color
        'box-shadow': boxShadow,
      };

      return style;
    },
  },
};
</script>

<style lang="scss" scoped></style>
