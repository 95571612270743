var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isPending)?_c('v-progress-circular',{attrs:{"indeterminate":"","size":15,"width":1}}):_vm._e(),(!_vm.isPending && _vm.testScenarioOutput.length > 0)?_c('div',[_c('h3',[_vm._v("Output Summary")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.testScenarioOutput,"items-per-page":10,"dense":""},scopedSlots:_vm._u([{key:`item.Output`,fn:function({ item }){return [_c('ul',[_c('li',[_vm._v(" "+_vm._s(item.name.LineNumber)+". "+_vm._s(item.name.LineName)+" "),(item.name.StepName)?_c('ul',[_c('li',[_vm._v(_vm._s(item.name.StepNumber)+". "+_vm._s(item.name.StepName))])]):_vm._e()])])]}},{key:`item.Input`,fn:function({ item }){return [(
            _vm.inputStructure.filter(
              (line) =>
                line.inputId == item.id ||
                line.steps.some((s) => s.inputId == item.id)
            )?.length > 0
          )?_c('div',_vm._l((_vm.inputStructure.filter(
              (line) =>
                line.inputId == item.id ||
                line.steps.some((s) => s.inputId == item.id)
            )),function(line){return _c('ul',{key:line.id},[_c('li',[_vm._v(" "+_vm._s(line.Number)+". "+_vm._s(line.Name)+" "),_c('ul',_vm._l((line.steps.filter(
                    (f) => f.inputId == item.id
                  )),function(step){return _c('li',{key:step.id},[_vm._v(" "+_vm._s(step.Number)+". "+_vm._s(step.Name)+" ")])}),0)])])}),0):_vm._e()]}}],null,true)})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }